const settings = {
  name: '247torax',
  googleAPIKey: 'AIzaSyAieBrtjSXRb9G4SYtxh0TL03MDwmyDgqA',
  state: [
    {
      slug: 'karnataka',
      name: 'Karnataka',
    },

    {
      slug: 'maharashtra',
      name: 'Maharashtra',
    },

    {
      slug: 'tamil-nadu',
      name: 'Tamil Nadu',
    },

    {
      slug: 'rajasthan',
      name: 'Rajasthan',
    },
    { slug: 'delhi', name: 'Delhi' },
    { slug: 'goa', name: 'Goa' },
    { slug: 'haryana', name: 'Haryana' },
    { slug: 'andhra-pradesh', name: 'Andhra Pradesh' },
    { slug: 'punjab', name: 'Punjab' },
    { slug: 'uttar-pradesh', name: 'Uttar Pradesh' },
    { slug: 'west-bengal', name: 'West Bengal' },
    { slug: 'chandigarh', name: 'Chandigarh' },
    { slug: 'uttarakhand', name: 'Uttarakhand' },
    { slug: 'jammu-kashmir', name: 'Jammu and Kashmir' },
    { slug: 'kerala', name: 'Kerala' },
    { slug: 'himachal-pradesh', name: 'Himachal Pradesh' },
    { slug: 'bihar', name: 'Bihar' },
    { slug: 'odisha', name: 'Odisha' },
    { slug: 'gujarat', name: 'Gujarat' },
    { slug: 'telangana', name: 'Telangana' },
    { slug: 'madhya-pradesh', name: 'Madhya Pradesh' },
    { slug: 'nagaland', name: 'Nagaland' },
    { slug: 'andaman-nicobar-islands', name: 'Andaman and Nicobar Islands' },
    { slug: 'puducherry', name: 'Puducherry' },
  ],
  city: [
    {
      value: 'delhi',
      label: 'Delhi',
      region: 'delhi',
    },
    {
      value: 'goa',
      label: 'Goa',
      region: 'goa',
    },
    {
      value: 'gurgaon',
      label: 'Gurgaon',
      region: 'haryana',
    },
    {
      value: 'coimbatore',
      label: 'Coimbatore',
      region: 'tamil-nadu',
    },
    {
      value: 'mysore',
      label: 'Mysore',
      region: 'karnataka',
    },
    {
      value: 'vijayawada',
      label: 'Vijayawada',
      region: 'andhra-pradesh',
    },
    {
      value: 'amritsar',
      label: 'Amritsar',
      region: 'punjab',
    },
    {
      value: 'ludhiana',
      label: 'Ludhiana',
      region: 'punjab',
    },
    {
      value: 'lucknow',
      label: 'Lucknow',
      region: 'uttar-pradesh',
    },
    {
      value: 'noida',
      label: 'Noida',
      region: 'uttar-pradesh',
    },
    {
      value: 'mangalore',
      label: 'Mangalore',
      region: 'karnataka',
    },
    {
      value: 'kolkata',
      label: 'Kolkata',
      region: 'west-bengal',
    },
    {
      value: 'chandigarh',
      label: 'Chandigarh',
      region: 'chandigarh',
    },
    {
      value: 'dehradun',
      label: 'Dehradun',
      region: 'uttarakhand',
    },
    {
      value: 'dwarka',
      label: 'Dwarka',
      region: 'delhi',
    },
    {
      value: 'jalandhar',
      label: 'Jalandhar',
      region: 'punjab',
    },
    {
      value: 'guntur',
      label: 'Guntur',
      region: 'andhra-pradesh',
    },
    {
      value: 'jammu',
      label: 'Jammu',
      region: 'jammu-and-kashmir',
    },
    {
      value: 'kochi',
      label: 'Kochi',
      region: 'kerala',
    },
    {
      value: 'ajmer',
      label: 'Ajmer',
      region: 'rajasthan',
    },
    {
      value: 'saket',
      label: 'Saket',
      region: 'delhi',
    },
    {
      value: 'ernakulam',
      label: 'Ernakulam',
      region: 'kerala',
    },
    {
      value: 'thrissur',
      label: 'Thrissur',
      region: 'kerala',
    },
    {
      value: 'belgaum',
      label: 'Belgaum',
      region: 'karnataka',
    },
    {
      value: 'munirka',
      label: 'Munirka',
      region: 'delhi',
    },
    {
      value: 'rajahmundry',
      label: 'Rajahmundry',
      region: 'andhra-pradesh',
    },
    {
      value: 'guwahati',
      label: 'Guwahati',
      region: 'assam',
    },
    {
      value: 'dharwad',
      label: 'Dharwad',
      region: 'karnataka',
    },
    {
      value: 'ambala',
      label: 'Ambala',
      region: 'haryana',
    },
    {
      value: 'patna',
      label: 'Patna',
      region: 'bihar',
    },
    {
      value: 'hospet',
      label: 'Hospet',
      region: 'karnataka',
    },
    {
      value: 'new-delhi',
      label: 'New Delhi',
      region: 'delhi',
    },
    {
      value: 'bhubaneswar',
      label: 'Bhubaneswar',
      region: 'odisha',
    },
    {
      value: 'ahmedabad',
      label: 'Ahmedabad',
      region: 'gujarat',
    },
    {
      value: 'nizamabad',
      label: 'Nizamabad',
      region: 'telangana',
    },
    {
      value: 'kanpur',
      label: 'Kanpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'karol-bagh',
      label: 'Karol Bagh',
      region: 'delhi',
    },
    {
      value: 'visakhapatnam',
      label: 'Visakhapatnam',
      region: 'andhra-pradesh',
    },
    {
      value: 'ghaziabad',
      label: 'Ghaziabad',
      region: 'uttar-pradesh',
    },
    {
      value: 'dindigul',
      label: 'Dindigul',
      region: 'tamil-nadu',
    },
    {
      value: 'bhopal',
      label: 'Bhopal',
      region: 'madhya-pradesh',
    },
    {
      value: 'agra',
      label: 'Agra',
      region: 'uttar-pradesh',
    },
    {
      value: 'south-delhi',
      label: 'South Delhi',
      region: 'delhi',
    },
    {
      value: 'vizianagaram',
      label: 'Vizianagaram',
      region: 'andhra-pradesh',
    },
    {
      value: 'palakkad',
      label: 'Palakkad',
      region: 'kerala',
    },
    {
      value: 'kannur',
      label: 'Kannur',
      region: 'kerala',
    },
    {
      value: 'udupi',
      label: 'Udupi',
      region: 'karnataka',
    },
    {
      value: 'tirunelveli',
      label: 'Tirunelveli',
      region: 'tamil-nadu',
    },
    {
      value: 'kottayam',
      label: 'Kottayam',
      region: 'kerala',
    },
    {
      value: 'rishikesh',
      label: 'Rishikesh',
      region: 'uttarakhand',
    },
    {
      value: 'kollam',
      label: 'Kollam',
      region: 'kerala',
    },
    {
      value: 'manali',
      label: 'Manali',
      region: 'himachal-pradesh',
    },
    {
      value: 'malviya-nagar',
      label: 'Malviya Nagar',
      region: 'delhi',
    },
    {
      value: 'vasant-kunj',
      label: 'Vasant Kunj',
      region: 'delhi',
    },
    {
      value: 'kodaikanal',
      label: 'Kodaikanal',
      region: 'tamil-nadu',
    },
    {
      value: 'lonavala',
      label: 'Lonavala',
      region: 'maharashtra',
    },
    {
      value: 'warangal',
      label: 'Warangal',
      region: 'telangana',
    },
    {
      value: 'nagpur',
      label: 'Nagpur',
      region: 'maharashtra',
    },
    {
      value: 'east-delhi',
      label: 'East Delhi',
      region: 'delhi',
    },
    {
      value: 'lajpat-nagar',
      label: 'Lajpat Nagar',
      region: 'delhi',
    },
    {
      value: 'chitradurga',
      label: 'Chitradurga',
      region: 'karnataka',
    },
    {
      value: 'krishnagiri',
      label: 'Krishnagiri',
      region: 'tamil-nadu',
    },
    {
      value: 'madanapalle',
      label: 'Madanapalle',
      region: 'andhra-pradesh',
    },
    {
      value: 'vaishali',
      label: 'Vaishali',
      region: 'bihar',
    },
    {
      value: 'pollachi',
      label: 'Pollachi',
      region: 'tamil-nadu',
    },
    {
      value: 'connaught-place',
      label: 'Connaught Place',
      region: 'delhi',
    },
    {
      value: 'thiruvananthapuram',
      label: 'Thiruvananthapuram',
      region: 'kerala',
    },
    {
      value: 'srikakulam',
      label: 'Srikakulam',
      region: 'andhra-pradesh',
    },
    {
      value: 'madikeri',
      label: 'Madikeri',
      region: 'karnataka',
    },
    {
      value: 'srinagar',
      label: 'Srinagar',
      region: 'jammu-and-kashmir',
    },
    {
      value: 'khammam',
      label: 'Khammam',
      region: 'telangana',
    },
    {
      value: 'ongole',
      label: 'Ongole',
      region: 'andhra-pradesh',
    },
    {
      value: 'theni',
      label: 'Theni',
      region: 'tamil-nadu',
    },
    {
      value: 'dharamshala',
      label: 'Dharamshala',
      region: 'himachal-pradesh',
    },
    {
      value: 'tiruvannamalai',
      label: 'Tiruvannamalai',
      region: 'tamil-nadu',
    },
    {
      value: 'bhimavaram',
      label: 'Bhimavaram',
      region: 'andhra-pradesh',
    },
    {
      value: 'janakpuri',
      label: 'Janakpuri',
      region: 'delhi',
    },
    {
      value: 'raichur',
      label: 'Raichur',
      region: 'karnataka',
    },
    {
      value: 'vellore',
      label: 'Vellore',
      region: 'tamil-nadu',
    },
    {
      value: 'chikmagalur',
      label: 'Chikmagalur',
      region: 'karnataka',
    },
    {
      value: 'kozhikode',
      label: 'Kozhikode',
      region: 'kerala',
    },
    {
      value: 'bijapur',
      label: 'Bijapur',
      region: 'karnataka',
    },
    {
      value: 'kalkaji',
      label: 'Kalkaji',
      region: 'delhi',
    },
    {
      value: 'nerul',
      label: 'Nerul',
      region: 'maharashtra',
    },
    {
      value: 'pudukkottai',
      label: 'Pudukkottai',
      region: 'tamil-nadu',
    },
    {
      value: 'puducherry',
      label: 'Puducherry',
      region: 'puducherry',
    },
    {
      value: 'hinjewadi',
      label: 'Hinjewadi',
      region: 'maharashtra',
    },
    {
      value: 'wayanad',
      label: 'Wayanad',
      region: 'kerala',
    },
    {
      value: 'munnar',
      label: 'Munnar',
      region: 'kerala',
    },
    {
      value: 'hauz-khas',
      label: 'Hauz Khas',
      region: 'delhi',
    },
    {
      value: 'thiruvallur',
      label: 'Thiruvallur',
      region: 'tamil-nadu',
    },
    {
      value: 'andheri',
      label: 'Andheri',
      region: 'maharashtra',
    },
    {
      value: 'port-blair',
      label: 'Port Blair',
      region: 'andaman-and-nicobar',
    },
    {
      value: 'bidar',
      label: 'Bidar',
      region: 'karnataka',
    },
    {
      value: 'green-park',
      label: 'Green Park',
      region: 'delhi',
    },
    {
      value: 'mayur-vihar',
      label: 'Mayur Vihar',
      region: 'delhi',
    },
    {
      value: 'alappuzha',
      label: 'Alappuzha',
      region: 'kerala',
    },
    {
      value: 'west-delhi',
      label: 'West Delhi',
      region: 'delhi',
    },
    {
      value: 'machilipatnam',
      label: 'Machilipatnam',
      region: 'andhra-pradesh',
    },
    {
      value: 'kandivali',
      label: 'Kandivali',
      region: 'maharashtra',
    },
    {
      value: 'gadag',
      label: 'Gadag',
      region: 'karnataka',
    },
    {
      value: 'dharmavaram',
      label: 'Dharmavaram',
      region: 'andhra-pradesh',
    },
    {
      value: 'grant-road',
      label: 'Grant Road',
      region: 'maharashtra',
    },
    {
      value: 'panaji',
      label: 'Panaji',
      region: 'goa',
    },
    {
      value: 'medak',
      label: 'Medak',
      region: 'telangana',
    },
    {
      value: 'kancheepuram',
      label: 'Kancheepuram',
      region: 'tamil-nadu',
    },
    {
      value: 'bhadravathi',
      label: 'Bhadravathi',
      region: 'karnataka',
    },
    {
      value: 'chilakaluripet',
      label: 'Chilakaluripet',
      region: 'andhra-pradesh',
    },
    {
      value: 'narasaraopet',
      label: 'Narasaraopet',
      region: 'andhra-pradesh',
    },
    {
      value: 'kasaragod',
      label: 'Kasaragod',
      region: 'kerala',
    },
    {
      value: 'telangana',
      label: 'Telangana',
      region: 'telangana',
    },
    {
      value: 'nagaland',
      label: 'Nagaland',
      region: 'nagaland',
    },
    {
      value: 'hindupur',
      label: 'Hindupur',
      region: 'andhra-pradesh',
    },
    {
      value: 'idukki',
      label: 'Idukki',
      region: 'kerala',
    },
    {
      value: 'abhiramapuram',
      label: 'Abhiramapuram',
      region: 'tamil-nadu',
    },
    {
      value: 'abids',
      label: 'Abids',
      region: 'telangana',
    },
    {
      value: 'abohar',
      label: 'Abohar',
      region: 'punjab',
    },
    {
      value: 'achalpur',
      label: 'Achalpur',
      region: 'maharashtra',
    },
    {
      value: 'adambakkam',
      label: 'Adambakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'adarsh-nagar',
      label: 'Adarsh Nagar',
      region: 'delhi',
    },
    {
      value: 'adilabad',
      label: 'Adilabad',
      region: 'telangana',
    },
    {
      value: 'adoni',
      label: 'Adoni',
      region: 'andhra-pradesh',
    },
    {
      value: 'adoor',
      label: 'Adoor',
      region: 'kerala',
    },
    {
      value: 'adyar',
      label: 'Adyar',
      region: 'tamil-nadu',
    },
    {
      value: 'aerocity',
      label: 'Aerocity',
      region: 'delhi',
    },
    {
      value: 'agartala',
      label: 'Agartala',
      region: 'tripura',
    },
    {
      value: 'agra',
      label: 'Agra',
      region: 'uttar-pradesh',
    },
    {
      value: 'ahmedabad',
      label: 'Ahmedabad',
      region: 'gujarat',
    },
    {
      value: 'ahmednagar',
      label: 'Ahmednagar',
      region: 'maharashtra',
    },
    {
      value: 'airoli',
      label: 'Airoli',
      region: 'maharashtra',
    },
    {
      value: 'aizawl',
      label: 'Aizawl',
      region: 'mizoram',
    },
    {
      value: 'ajc-boss-road',
      label: 'AJC Boss Road',
      region: 'west-bengal',
    },
    {
      value: 'ajmer',
      label: 'Ajmer',
      region: 'rajasthan',
    },
    {
      value: 'ajmer-road',
      label: 'Ajmer Road',
      region: 'rajasthan',
    },
    {
      value: 'akbarpur',
      label: 'Akbarpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'akola',
      label: 'Akola',
      region: 'maharashtra',
    },
    {
      value: 'alandi',
      label: 'Alandi',
      region: 'maharashtra',
    },
    {
      value: 'alandur',
      label: 'Alandur',
      region: 'tamil-nadu',
    },
    {
      value: 'alapakkam',
      label: 'Alapakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'alappuzha',
      label: 'Alappuzha',
      region: 'kerala',
    },
    {
      value: 'aligarh',
      label: 'Aligarh',
      region: 'uttar-pradesh',
    },
    {
      value: 'alipore',
      label: 'Alipore',
      region: 'west-bengal',
    },
    {
      value: 'aluva',
      label: 'Aluva',
      region: 'kerala',
    },
    {
      value: 'alwal',
      label: 'Alwal',
      region: 'telangana',
    },
    {
      value: 'alwar',
      label: 'Alwar',
      region: 'rajasthan',
    },
    {
      value: 'alwarpet',
      label: 'Alwarpet',
      region: 'tamil-nadu',
    },
    {
      value: 'alwarthirunagar',
      label: 'Alwarthirunagar',
      region: 'tamil-nadu',
    },
    {
      value: 'amaravati',
      label: 'Amaravati',
      region: 'andhra-pradesh',
    },
    {
      value: 'ambala',
      label: 'Ambala',
      region: 'haryana',
    },
    {
      value: 'ambattur',
      label: 'Ambattur',
      region: 'tamil-nadu',
    },
    {
      value: 'ambedkar-nagar',
      label: 'Ambedkar Nagar',
      region: 'uttar-pradesh',
    },
    {
      value: 'amberpet',
      label: 'Amberpet',
      region: 'telangana',
    },
    {
      value: 'ambikapur',
      label: 'Ambikapur',
      region: 'chhattisgarh',
    },
    {
      value: 'amboli',
      label: 'Amboli',
      region: 'maharashtra',
    },
    {
      value: 'ambur',
      label: 'Ambur',
      region: 'tamil-nadu',
    },
    {
      value: 'ameerpet',
      label: 'Ameerpet',
      region: 'telangana',
    },
    {
      value: 'aminjikarai',
      label: 'Aminjikarai',
      region: 'tamil-nadu',
    },
    {
      value: 'amli',
      label: 'Amli',
      region: 'dadra-nagar-haveli',
    },
    {
      value: 'amravati',
      label: 'Amravati',
      region: 'maharashtra',
    },
    {
      value: 'amreli',
      label: 'Amreli',
      region: 'gujarat',
    },
    {
      value: 'amritsar',
      label: 'Amritsar',
      region: 'punjab',
    },
    {
      value: 'amroha',
      label: 'Amroha',
      region: 'uttar-pradesh',
    },
    {
      value: 'amroli',
      label: 'Amroli',
      region: 'gujarat',
    },
    {
      value: 'anakaputhur',
      label: 'Anakaputhur',
      region: 'tamil-nadu',
    },
    {
      value: 'anand',
      label: 'Anand',
      region: 'gujarat',
    },
    {
      value: 'anand-lok',
      label: 'Anand Lok',
      region: 'delhi',
    },
    {
      value: 'anantapur',
      label: 'Anantapur',
      region: 'andhra-pradesh',
    },
    {
      value: 'anantapura',
      label: 'Anantapura',
      region: 'karnataka',
    },
    {
      value: 'anantnag',
      label: 'Anantnag',
      region: 'jammu-kashmir',
    },
    {
      value: 'andheri',
      label: 'Andheri',
      region: 'maharashtra',
    },
    {
      value: 'angamaly',
      label: 'Angamaly',
      region: 'kerala',
    },
    {
      value: 'anna-nagar',
      label: 'Anna Nagar',
      region: 'tamil-nadu',
    },
    {
      value: 'annanur',
      label: 'Annanur',
      region: 'tamil-nadu',
    },
    {
      value: 'arekere',
      label: 'Arekere',
      region: 'karnataka',
    },
    {
      value: 'arossim-beach',
      label: 'Arossim Beach',
      region: 'goa',
    },
    {
      value: 'arrah',
      label: 'Arrah',
      region: 'bihar',
    },
    {
      value: 'arumbakkam',
      label: 'Arumbakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'asansol',
      label: 'Asansol',
      region: 'west-bengal',
    },
    {
      value: 'ashok-nagar',
      label: 'Ashok Nagar',
      region: 'tamil-nadu',
    },
    {
      value: 'ashok-vihar',
      label: 'Ashok Vihar',
      region: 'delhi',
    },
    {
      value: 'attingal',
      label: 'Attingal',
      region: 'kerala',
    },
    {
      value: 'aundh',
      label: 'Aundh',
      region: 'maharashtra',
    },
    {
      value: 'aurangabad',
      label: 'Aurangabad',
      region: 'maharashtra',
    },
    {
      value: 'aurangabad',
      label: 'Aurangabad',
      region: 'bihar',
    },
    {
      value: 'avadi',
      label: 'Avadi',
      region: 'tamil-nadu',
    },
    {
      value: 'ayanavaram',
      label: 'Ayanavaram',
      region: 'tamil-nadu',
    },
    {
      value: 'ayodhya',
      label: 'Ayodhya',
      region: 'uttar-pradesh',
    },
    {
      value: 'azamgarh',
      label: 'Azamgarh',
      region: 'uttar-pradesh',
    },
    {
      value: 'baga-beach',
      label: 'Baga Beach',
      region: 'goa',
    },
    {
      value: 'bagaha',
      label: 'Bagaha',
      region: 'bihar',
    },
    {
      value: 'bagalkot',
      label: 'Bagalkot',
      region: 'karnataka',
    },
    {
      value: 'baguiati',
      label: 'Baguiati',
      region: 'west-bengal',
    },
    {
      value: 'bahadurgarh',
      label: 'Bahadurgarh',
      region: 'haryana',
    },
    {
      value: 'baharampur',
      label: 'Baharampur',
      region: 'west-bengal',
    },
    {
      value: 'bahraich',
      label: 'Bahraich',
      region: 'uttar-pradesh',
    },
    {
      value: 'baidyabati',
      label: 'Baidyabati',
      region: 'west-bengal',
    },
    {
      value: 'balangir',
      label: 'Balangir',
      region: 'odisha',
    },
    {
      value: 'balasore',
      label: 'Balasore',
      region: 'odisha',
    },
    {
      value: 'ballard-estate',
      label: 'Ballard Estate',
      region: 'maharashtra',
    },
    {
      value: 'ballia',
      label: 'Ballia',
      region: 'uttar-pradesh',
    },
    {
      value: 'ballygunge',
      label: 'Ballygunge',
      region: 'west-bengal',
    },
    {
      value: 'balurghat',
      label: 'Balurghat',
      region: 'west-bengal',
    },
    {
      value: 'banashankari',
      label: 'Banashankari',
      region: 'karnataka',
    },
    {
      value: 'banaswadi',
      label: 'Banaswadi',
      region: 'karnataka',
    },
    {
      value: 'banda',
      label: 'Banda',
      region: 'uttar-pradesh',
    },
    {
      value: 'bandra',
      label: 'Bandra',
      region: 'maharashtra',
    },
    {
      value: 'baner',
      label: 'Baner',
      region: 'delhi',
    },
    {
      value: 'bangalore',
      label: 'Bangalore',
      region: 'karnataka',
    },
    {
      value: 'bangaon',
      label: 'Bangaon',
      region: 'west-bengal',
    },
    {
      value: 'bani-park',
      label: 'Bani Park',
      region: 'rajasthan',
    },
    {
      value: 'banjara-hills',
      label: 'Banjara Hills',
      region: 'telangana',
    },
    {
      value: 'bankura',
      label: 'Bankura',
      region: 'west-bengal',
    },
    {
      value: 'bansberia',
      label: 'Bansberia',
      region: 'west-bengal',
    },
    {
      value: 'bansdroni',
      label: 'Bansdroni',
      region: 'west-bengal',
    },
    {
      value: 'banswara',
      label: 'Banswara',
      region: 'rajasthan',
    },
    {
      value: 'bapu-nagar',
      label: 'Bapu Nagar',
      region: 'rajasthan',
    },
    {
      value: 'bara-bazar',
      label: 'Bara Bazar',
      region: 'west-bengal',
    },
    {
      value: 'barabanki',
      label: 'Barabanki',
      region: 'uttar-pradesh',
    },
    {
      value: 'barakhamba-road',
      label: 'Barakhamba Road',
      region: 'delhi',
    },
    {
      value: 'baramati',
      label: 'Baramati',
      region: 'maharashtra',
    },
    {
      value: 'baramulla',
      label: 'Baramulla',
      region: 'jammu-kashmir',
    },
    {
      value: 'baran',
      label: 'Baran',
      region: 'rajasthan',
    },
    {
      value: 'baranagar',
      label: 'Baranagar',
      region: 'west-bengal',
    },
    {
      value: 'barasat',
      label: 'Barasat',
      region: 'west-bengal',
    },
    {
      value: 'barbil',
      label: 'Barbil',
      region: 'odisha',
    },
    {
      value: 'bardhaman',
      label: 'Bardhaman',
      region: 'west-bengal',
    },
    {
      value: 'bardoli',
      label: 'Bardoli',
      region: 'gujarat',
    },
    {
      value: 'bareilly',
      label: 'Bareilly',
      region: 'uttar-pradesh',
    },
    {
      value: 'bareja',
      label: 'Bareja',
      region: 'gujarat',
    },
    {
      value: 'barmer',
      label: 'Barmer',
      region: 'rajasthan',
    },
    {
      value: 'barnala',
      label: 'Barnala',
      region: 'punjab',
    },
    {
      value: 'barrackpore',
      label: 'Barrackpore',
      region: 'west-bengal',
    },
    {
      value: 'barshi',
      label: 'Barshi',
      region: 'maharashtra',
    },
    {
      value: 'baruipur',
      label: 'Baruipur',
      region: 'west-bengal',
    },
    {
      value: 'basavanagudi',
      label: 'Basavanagudi',
      region: 'karnataka',
    },
    {
      value: 'basaveshwara-nagar',
      label: 'Basaveshwara Nagar',
      region: 'karnataka',
    },
    {
      value: 'basin-bridge',
      label: 'Basin Bridge',
      region: 'tamil-nadu',
    },
    {
      value: 'basirhat',
      label: 'Basirhat',
      region: 'west-bengal',
    },
    {
      value: 'basti',
      label: 'Basti',
      region: 'uttar-pradesh',
    },
    {
      value: 'batala',
      label: 'Batala',
      region: 'punjab',
    },
    {
      value: 'bathinda',
      label: 'Bathinda',
      region: 'punjab',
    },
    {
      value: 'batwa',
      label: 'Batwa',
      region: 'gujarat',
    },
    {
      value: 'bavdhan',
      label: 'Bavdhan',
      region: 'maharashtra',
    },
    {
      value: 'beawar',
      label: 'Beawar',
      region: 'rajasthan',
    },
    {
      value: 'beed',
      label: 'Beed',
      region: 'maharashtra',
    },
    {
      value: 'begumpet',
      label: 'Begumpet',
      region: 'telangana',
    },
    {
      value: 'begusarai',
      label: 'Begusarai',
      region: 'bihar',
    },
    {
      value: 'behala',
      label: 'Behala',
      region: 'west-bengal',
    },
    {
      value: 'belapurpada',
      label: 'Belapurpada',
      region: 'maharashtra',
    },
    {
      value: 'belgachia',
      label: 'Belgachia',
      region: 'west-bengal',
    },
    {
      value: 'belgaum',
      label: 'Belgaum',
      region: 'karnataka',
    },
    {
      value: 'belghoria',
      label: 'Belghoria',
      region: 'west-bengal',
    },
    {
      value: 'bellandur',
      label: 'Bellandur',
      region: 'karnataka',
    },
    {
      value: 'bellary',
      label: 'Bellary',
      region: 'karnataka',
    },
    {
      value: 'benaulim-beach',
      label: 'Benaulim Beach',
      region: 'goa',
    },
    {
      value: 'berhampur',
      label: 'Berhampur',
      region: 'odisha',
    },
    {
      value: 'besant-nagar',
      label: 'Besant Nagar',
      region: 'tamil-nadu',
    },
    {
      value: 'bettiah',
      label: 'Bettiah',
      region: 'bihar',
    },
    {
      value: 'betul',
      label: 'Betul',
      region: 'madhya-pradesh',
    },
    {
      value: 'bhadrak',
      label: 'Bhadrak',
      region: 'odisha',
    },
    {
      value: 'bhadravati',
      label: 'Bhadravati',
      region: 'karnataka',
    },
    {
      value: 'bhadreswar',
      label: 'Bhadreswar',
      region: 'west-bengal',
    },
    {
      value: 'bhagalpur',
      label: 'Bhagalpur',
      region: 'bihar',
    },
    {
      value: 'bhamburda',
      label: 'Bhamburda',
      region: 'maharashtra',
    },
    {
      value: 'bhandup',
      label: 'Bhandup',
      region: 'maharashtra',
    },
    {
      value: 'bharatpur',
      label: 'Bharatpur',
      region: 'rajasthan',
    },
    {
      value: 'bharuch',
      label: 'Bharuch',
      region: 'gujarat',
    },
    {
      value: 'bhatpara',
      label: 'Bhatpara',
      region: 'west-bengal',
    },
    {
      value: 'bhavnagar',
      label: 'Bhavnagar',
      region: 'gujarat',
    },
    {
      value: 'bhawanipatna',
      label: 'Bhawanipatna',
      region: 'odisha',
    },
    {
      value: 'bhilai',
      label: 'Bhilai',
      region: 'chhattisgarh',
    },
    {
      value: 'bhilwara',
      label: 'Bhilwara',
      region: 'rajasthan',
    },
    {
      value: 'bhimavaram',
      label: 'Bhimavaram',
      region: 'andhra-pradesh',
    },
    {
      value: 'bhind',
      label: 'Bhind',
      region: 'madhya-pradesh',
    },
    {
      value: 'bhiwadi',
      label: 'Bhiwadi',
      region: 'rajasthan',
    },
    {
      value: 'bhiwandi',
      label: 'Bhiwandi',
      region: 'maharashtra',
    },
    {
      value: 'bhiwani',
      label: 'Bhiwani',
      region: 'haryana',
    },
    {
      value: 'bhojpur',
      label: 'Bhojpur',
      region: 'bihar',
    },
    {
      value: 'bhopal',
      label: 'Bhopal',
      region: 'madhya-pradesh',
    },
    {
      value: 'bhosari',
      label: 'Bhosari',
      region: 'maharashtra',
    },
    {
      value: 'bhubaneswar',
      label: 'Bhubaneswar',
      region: 'odisha',
    },
    {
      value: 'bhuj',
      label: 'Bhuj',
      region: 'gujarat',
    },
    {
      value: 'bhusawal',
      label: 'Bhusawal',
      region: 'maharashtra',
    },
    {
      value: 'bibvewadi',
      label: 'Bibvewadi',
      region: 'maharashtra',
    },
    {
      value: 'bicholim',
      label: 'Bicholim',
      region: 'goa',
    },
    {
      value: 'bidar',
      label: 'Bidar',
      region: 'karnataka',
    },
    {
      value: 'bidhan-nagar',
      label: 'Bidhan Nagar',
      region: 'west-bengal',
    },
    {
      value: 'bihar-sharif',
      label: 'Bihar Sharif',
      region: 'bihar',
    },
    {
      value: 'bijapur',
      label: 'Bijapur',
      region: 'karnataka',
    },
    {
      value: 'bijnor',
      label: 'Bijnor',
      region: 'uttar-pradesh',
    },
    {
      value: 'bikaner',
      label: 'Bikaner',
      region: 'rajasthan',
    },
    {
      value: 'bilaspur',
      label: 'Bilaspur',
      region: 'chhattisgarh',
    },
    {
      value: 'bishnupur',
      label: 'Bishnupur',
      region: 'manipur',
    },
    {
      value: 'boat-club-road',
      label: 'Boat Club Road',
      region: 'maharashtra',
    },
    {
      value: 'bokaro',
      label: 'Bokaro',
      region: 'jharkhand',
    },
    {
      value: 'bommanahalli',
      label: 'Bommanahalli',
      region: 'karnataka',
    },
    {
      value: 'bommasandra',
      label: 'Bommasandra',
      region: 'karnataka',
    },
    {
      value: 'bongaigaon',
      label: 'Bongaigaon',
      region: 'assam',
    },
    {
      value: 'borivali',
      label: 'Borivali',
      region: 'maharashtra',
    },
    {
      value: 'botad',
      label: 'Botad',
      region: 'gujarat',
    },
    {
      value: 'btm-layout',
      label: 'BTM Layout',
      region: 'karnataka',
    },
    {
      value: 'budaun',
      label: 'Budaun',
      region: 'uttar-pradesh',
    },
    {
      value: 'budge-budge',
      label: 'Budge Budge',
      region: 'west-bengal',
    },
    {
      value: 'bulandshahr',
      label: 'Bulandshahr',
      region: 'uttar-pradesh',
    },
    {
      value: 'bundi',
      label: 'Bundi',
      region: 'rajasthan',
    },
    {
      value: 'burhanpur',
      label: 'Burhanpur',
      region: 'madhya-pradesh',
    },
    {
      value: 'burla',
      label: 'Burla',
      region: 'odisha',
    },
    {
      value: 'buxar',
      label: 'Buxar',
      region: 'bihar',
    },
    {
      value: 'byculla',
      label: 'Byculla',
      region: 'maharashtra',
    },
    {
      value: 'c-scheme',
      label: 'C Scheme',
      region: 'rajasthan',
    },
    {
      value: 'calangute-beach',
      label: 'Calangute Beach',
      region: 'goa',
    },
    {
      value: 'canacona',
      label: 'Canacona',
      region: 'goa',
    },
    {
      value: 'candolim-beach',
      label: 'Candolim Beach',
      region: 'goa',
    },
    {
      value: 'chakala',
      label: 'Chakala',
      region: 'maharashtra',
    },
    {
      value: 'chakan',
      label: 'Chakan',
      region: 'maharashtra',
    },
    {
      value: 'chakdaha',
      label: 'Chakdaha',
      region: 'west-bengal',
    },
    {
      value: 'chalakudy',
      label: 'Chalakudy',
      region: 'kerala',
    },
    {
      value: 'chamba',
      label: 'Chamba',
      region: 'himachal-pradesh',
    },
    {
      value: 'chamba',
      label: 'Chamba',
      region: 'himachal-pradesh',
    },
    {
      value: 'champdani',
      label: 'Champdani',
      region: 'west-bengal',
    },
    {
      value: 'chandapura',
      label: 'Chandapura',
      region: 'karnataka',
    },
    {
      value: 'chandauli',
      label: 'Chandauli',
      region: 'uttar-pradesh',
    },
    {
      value: 'chandausi',
      label: 'Chandausi',
      region: 'uttar-pradesh',
    },
    {
      value: 'chandigarh',
      label: 'Chandigarh',
      region: 'chandigarh',
    },
    {
      value: 'chandni-chowk',
      label: 'Chandni Chowk',
      region: 'delhi',
    },
    {
      value: 'chandra-nagar',
      label: 'Chandra Nagar',
      region: 'telangana',
    },
    {
      value: 'chandrapur',
      label: 'Chandrapur',
      region: 'maharashtra',
    },
    {
      value: 'changanassery',
      label: 'Changanassery',
      region: 'kerala',
    },
    {
      value: 'charni-road',
      label: 'Charni Road',
      region: 'maharashtra',
    },
    {
      value: 'chattarpur',
      label: 'Chattarpur',
      region: 'delhi',
    },
    {
      value: 'chavakkad',
      label: 'Chavakkad',
      region: 'kerala',
    },
    {
      value: 'chembur',
      label: 'Chembur',
      region: 'maharashtra',
    },
    {
      value: 'chengannur',
      label: 'Chengannur',
      region: 'kerala',
    },
    {
      value: 'chennai',
      label: 'Chennai',
      region: 'tamil-nadu',
    },
    {
      value: 'chepauk',
      label: 'Chepauk',
      region: 'tamil-nadu',
    },
    {
      value: 'chetput',
      label: 'Chetput',
      region: 'tamil-nadu',
    },
    {
      value: 'chhapra',
      label: 'Chhapra',
      region: 'bihar',
    },
    {
      value: 'chhatarpur',
      label: 'Chhatarpur',
      region: 'delhi',
    },
    {
      value: 'chhatarpur',
      label: 'Chhatarpur',
      region: 'madhya-pradesh',
    },
    {
      value: 'chhindwara',
      label: 'Chhindwara',
      region: 'madhya-pradesh',
    },
    {
      value: 'chikhli',
      label: 'Chikhli',
      region: 'maharashtra',
    },
    {
      value: 'chikmagalur',
      label: 'Chikmagalur',
      region: 'karnataka',
    },
    {
      value: 'chilakaluripet',
      label: 'Chilakaluripet',
      region: 'andhra-pradesh',
    },
    {
      value: 'chinar-park',
      label: 'Chinar Park',
      region: 'west-bengal',
    },
    {
      value: 'chinchpokli',
      label: 'Chinchpokli',
      region: 'maharashtra',
    },
    {
      value: 'chintadripet',
      label: 'Chintadripet',
      region: 'tamil-nadu',
    },
    {
      value: 'chitlapakkam',
      label: 'Chitlapakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'chitradurga',
      label: 'Chitradurga',
      region: 'karnataka',
    },
    {
      value: 'chittoor',
      label: 'Chittoor',
      region: 'andhra-pradesh',
    },
    {
      value: 'chittorgarh',
      label: 'Chittorgarh',
      region: 'rajasthan',
    },
    {
      value: 'choolai',
      label: 'Choolai',
      region: 'tamil-nadu',
    },
    {
      value: 'chrompet',
      label: 'Chrompet',
      region: 'tamil-nadu',
    },
    {
      value: 'chunabhatti',
      label: 'Chunabhatti',
      region: 'maharashtra',
    },
    {
      value: 'churchgate',
      label: 'Churchgate',
      region: 'maharashtra',
    },
    {
      value: 'churu',
      label: 'Churu',
      region: 'rajasthan',
    },
    {
      value: 'coimbatore',
      label: 'Coimbatore',
      region: 'tamil-nadu',
    },
    {
      value: 'colaba',
      label: 'Colaba',
      region: 'maharashtra',
    },
    {
      value: 'colva-beach',
      label: 'Colva Beach',
      region: 'goa',
    },
    {
      value: 'connaught-place',
      label: 'Connaught Place',
      region: 'delhi',
    },
    {
      value: 'cooch-behar',
      label: 'Cooch Behar',
      region: 'west-bengal',
    },
    {
      value: 'cossipore',
      label: 'Cossipore',
      region: 'west-bengal',
    },
    {
      value: 'cotton-green',
      label: 'Cotton Green',
      region: 'maharashtra',
    },
    {
      value: 'cox-town',
      label: 'Cox Town',
      region: 'karnataka',
    },
    {
      value: 'cuddalore',
      label: 'Cuddalore',
      region: 'tamil-nadu',
    },
    {
      value: 'cumballa-hill',
      label: 'Cumballa Hill',
      region: 'maharashtra',
    },
    {
      value: 'cuncolim',
      label: 'Cuncolim',
      region: 'goa',
    },
    {
      value: 'cupa',
      label: 'Cupa',
      region: 'goa',
    },
    {
      value: 'curchorem',
      label: 'Curchorem',
      region: 'goa',
    },
    {
      value: 'currey-road',
      label: 'Currey Road',
      region: 'maharashtra',
    },
    {
      value: 'cuttack',
      label: 'Cuttack',
      region: 'odisha',
    },
    {
      value: 'dadar',
      label: 'Dadar',
      region: 'maharashtra',
    },
    {
      value: 'dahisar',
      label: 'Dahisar',
      region: 'maharashtra',
    },
    {
      value: 'dahod',
      label: 'Dahod',
      region: 'gujarat',
    },
    {
      value: 'damoh',
      label: 'Damoh',
      region: 'madhya-pradesh',
    },
    {
      value: 'danapur',
      label: 'Danapur',
      region: 'bihar',
    },
    {
      value: 'dankuni',
      label: 'Dankuni',
      region: 'west-bengal',
    },
    {
      value: 'dapodi',
      label: 'Dapodi',
      region: 'maharashtra',
    },
    {
      value: 'darbhanga',
      label: 'Darbhanga',
      region: 'bihar',
    },
    {
      value: 'darjeeling',
      label: 'Darjeeling',
      region: 'west-bengal',
    },
    {
      value: 'daryaganj',
      label: 'Daryaganj',
      region: 'delhi',
    },
    {
      value: 'datia',
      label: 'Datia',
      region: 'madhya-pradesh',
    },
    {
      value: 'davanagere',
      label: 'Davanagere',
      region: 'karnataka',
    },
    {
      value: 'deesa',
      label: 'Deesa',
      region: 'gujarat',
    },
    {
      value: 'defence-colony',
      label: 'Defence Colony',
      region: 'delhi',
    },
    {
      value: 'dehradun',
      label: 'Dehradun',
      region: 'uttarakhand',
    },
    {
      value: 'dehu-road',
      label: 'Dehu Road',
      region: 'maharashtra',
    },
    {
      value: 'delhi',
      label: 'Delhi',
      region: 'delhi',
    },
    {
      value: 'deoghar',
      label: 'Deoghar',
      region: 'jharkhand',
    },
    {
      value: 'deoria',
      label: 'Deoria',
      region: 'uttar-pradesh',
    },
    {
      value: 'dewas',
      label: 'Dewas',
      region: 'madhya-pradesh',
    },
    {
      value: 'dhakuria',
      label: 'Dhakuria',
      region: 'west-bengal',
    },
    {
      value: 'dhamtari',
      label: 'Dhamtari',
      region: 'chhattisgarh',
    },
    {
      value: 'dhanbad',
      label: 'Dhanbad',
      region: 'jharkhand',
    },
    {
      value: 'dhankawadi',
      label: 'Dhankawadi',
      region: 'maharashtra',
    },
    {
      value: 'dhanori',
      label: 'Dhanori',
      region: 'maharashtra',
    },
    {
      value: 'dhar',
      label: 'Dhar',
      region: 'madhya-pradesh',
    },
    {
      value: 'dharamshala',
      label: 'Dharamshala',
      region: 'himachal-pradesh',
    },
    {
      value: 'dharamshala',
      label: 'Dharamshala',
      region: 'himachal-pradesh',
    },
    {
      value: 'dharavi',
      label: 'Dharavi',
      region: 'maharashtra',
    },
    {
      value: 'dharmanagar',
      label: 'Dharmanagar',
      region: 'tripura',
    },
    {
      value: 'dharmatala',
      label: 'Dharmatala',
      region: 'west-bengal',
    },
    {
      value: 'dharmavaram',
      label: 'Dharmavaram',
      region: 'andhra-pradesh',
    },
    {
      value: 'dharwad',
      label: 'Dharwad',
      region: 'karnataka',
    },
    {
      value: 'dhayari',
      label: 'Dhayari',
      region: 'maharashtra',
    },
    {
      value: 'dhenkanal',
      label: 'Dhenkanal',
      region: 'odisha',
    },
    {
      value: 'dholka',
      label: 'Dholka',
      region: 'gujarat',
    },
    {
      value: 'dholpur',
      label: 'Dholpur',
      region: 'rajasthan',
    },
    {
      value: 'dhule',
      label: 'Dhule',
      region: 'maharashtra',
    },
    {
      value: 'dhulian',
      label: 'Dhulian',
      region: 'west-bengal',
    },
    {
      value: 'dibrugarh',
      label: 'Dibrugarh',
      region: 'assam',
    },
    {
      value: 'dighi',
      label: 'Dighi',
      region: 'maharashtra',
    },
    {
      value: 'dilshad-garden',
      label: 'Dilshad Garden',
      region: 'delhi',
    },
    {
      value: 'dilsukhnagar',
      label: 'Dilsukhnagar',
      region: 'telangana',
    },
    {
      value: 'nagaland',
      label: 'Nagaland',
      region: 'nagaland',
    },
    {
      value: 'dimapur',
      label: 'Dimapur',
      region: 'nagaland',
    },
    {
      value: 'dindigul',
      label: 'Dindigul',
      region: 'tamil-nadu',
    },
    {
      value: 'dindori',
      label: 'Dindori',
      region: 'madhya-pradesh',
    },
    {
      value: 'domlur',
      label: 'Domlur',
      region: 'karnataka',
    },
    {
      value: 'dum-dum',
      label: 'Dum Dum',
      region: 'west-bengal',
    },
    {
      value: 'dungarpur',
      label: 'Dungarpur',
      region: 'rajasthan',
    },
    {
      value: 'durg',
      label: 'Durg',
      region: 'chhattisgarh',
    },
    {
      value: 'durgapur',
      label: 'Durgapur',
      region: 'west-bengal',
    },
    {
      value: 'durgapura',
      label: 'Durgapura',
      region: 'rajasthan',
    },
    {
      value: 'dwarka',
      label: 'Dwarka',
      region: 'delhi',
    },
    {
      value: 'egmore',
      label: 'Egmore',
      region: 'tamil-nadu',
    },
    {
      value: 'ejipura',
      label: 'Ejipura',
      region: 'karnataka',
    },
    {
      value: 'ekkaduthangal',
      label: 'Ekkaduthangal',
      region: 'tamil-nadu',
    },
    {
      value: 'electronic-city',
      label: 'Electronic City',
      region: 'karnataka',
    },
    {
      value: 'eluru',
      label: 'Eluru',
      region: 'andhra-pradesh',
    },
    {
      value: 'ennore',
      label: 'Ennore',
      region: 'tamil-nadu',
    },
    {
      value: 'erattupetta',
      label: 'Erattupetta',
      region: 'kerala',
    },
    {
      value: 'erode',
      label: 'Erode',
      region: 'tamil-nadu',
    },
    {
      value: 'etah',
      label: 'Etah',
      region: 'uttar-pradesh',
    },
    {
      value: 'etawah',
      label: 'Etawah',
      region: 'uttar-pradesh',
    },
    {
      value: 'faizabad',
      label: 'Faizabad',
      region: 'uttar-pradesh',
    },
    {
      value: 'faridabad',
      label: 'Faridabad',
      region: 'haryana',
    },
    {
      value: 'faridkot',
      label: 'Faridkot',
      region: 'punjab',
    },
    {
      value: 'farrukhabad',
      label: 'Farrukhabad',
      region: 'uttar-pradesh',
    },
    {
      value: 'fatehpur',
      label: 'Fatehpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'fatima-nagar',
      label: 'Fatima Nagar',
      region: 'maharashtra',
    },
    {
      value: 'fazilka',
      label: 'Fazilka',
      region: 'punjab',
    },
    {
      value: 'firozabad',
      label: 'Firozabad',
      region: 'uttar-pradesh',
    },
    {
      value: 'firozpur',
      label: 'Firozpur',
      region: 'punjab',
    },
    {
      value: 'gachibowli',
      label: 'Gachibowli',
      region: 'telangana',
    },
    {
      value: 'gadag',
      label: 'Gadag',
      region: 'karnataka',
    },
    {
      value: 'gandhidham',
      label: 'Gandhidham',
      region: 'gujarat',
    },
    {
      value: 'gandhinagar',
      label: 'Gandhinagar',
      region: 'gujarat',
    },
    {
      value: 'gangtok',
      label: 'Gangtok',
      region: 'sikkim',
    },
    {
      value: 'garia',
      label: 'Garia',
      region: 'west-bengal',
    },
    {
      value: 'gaya',
      label: 'Gaya',
      region: 'bihar',
    },
    {
      value: 'george-town',
      label: 'George Town',
      region: 'tamil-nadu',
    },
    {
      value: 'gerugambakkam',
      label: 'Gerugambakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'ghatkesar',
      label: 'Ghatkesar',
      region: 'telangana',
    },
    {
      value: 'ghatkopar',
      label: 'Ghatkopar',
      region: 'maharashtra',
    },
    {
      value: 'ghaziabad',
      label: 'Ghaziabad',
      region: 'uttar-pradesh',
    },
    {
      value: 'ghazipur',
      label: 'Ghazipur',
      region: 'uttar-pradesh',
    },
    {
      value: 'ghodapdeo',
      label: 'Ghodapdeo',
      region: 'maharashtra',
    },
    {
      value: 'girgaum',
      label: 'Girgaum',
      region: 'maharashtra',
    },
    {
      value: 'giridih',
      label: 'Giridih',
      region: 'jharkhand',
    },
    {
      value: 'girinagar',
      label: 'Girinagar',
      region: 'karnataka',
    },
    {
      value: 'goa',
      label: 'Goa',
      region: 'goa',
    },
    {
      value: 'godhra',
      label: 'Godhra',
      region: 'gujarat',
    },
    {
      value: 'golf-links',
      label: 'Golf Links',
      region: 'delhi',
    },
    {
      value: 'gonda',
      label: 'Gonda',
      region: 'uttar-pradesh',
    },
    {
      value: 'gondal',
      label: 'Gondal',
      region: 'gujarat',
    },
    {
      value: 'gondia',
      label: 'Gondia',
      region: 'maharashtra',
    },
    {
      value: 'gopalapuram',
      label: 'Gopalapuram',
      region: 'tamil-nadu',
    },
    {
      value: 'gorakhpur',
      label: 'Gorakhpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'goregaon',
      label: 'Goregaon',
      region: 'maharashtra',
    },
    {
      value: 'gorwa',
      label: 'Gorwa',
      region: 'gujarat',
    },
    {
      value: 'gottigere',
      label: 'Gottigere',
      region: 'karnataka',
    },
    {
      value: 'govandi',
      label: 'Govandi',
      region: 'maharashtra',
    },
    {
      value: 'grant-road',
      label: 'Grant Road',
      region: 'maharashtra',
    },
    {
      value: 'greater-kailash',
      label: 'Greater Kailash',
      region: 'delhi',
    },
    {
      value: 'greater-noida',
      label: 'Greater Noida',
      region: 'uttar-pradesh',
    },
    {
      value: 'green-park',
      label: 'Green Park',
      region: 'delhi',
    },
    {
      value: 'gudivada',
      label: 'Gudivada',
      region: 'andhra-pradesh',
    },
    {
      value: 'gudiyatham',
      label: 'Gudiyatham',
      region: 'tamil-nadu',
    },
    {
      value: 'guindy',
      label: 'Guindy',
      region: 'tamil-nadu',
    },
    {
      value: 'gulabi-bagh',
      label: 'Gulabi Bagh',
      region: 'delhi',
    },
    {
      value: 'gulbarga',
      label: 'Gulbarga',
      region: 'karnataka',
    },
    {
      value: 'gulmarg',
      label: 'Gulmarg',
      region: 'jammu-kashmir',
    },
    {
      value: 'guna',
      label: 'Guna',
      region: 'madhya-pradesh',
    },
    {
      value: 'guntakal',
      label: 'Guntakal',
      region: 'andhra-pradesh',
    },
    {
      value: 'guntur',
      label: 'Guntur',
      region: 'andhra-pradesh',
    },
    {
      value: 'gurdaspur',
      label: 'Gurdaspur',
      region: 'punjab',
    },
    {
      value: 'gurgaon',
      label: 'Gurgaon',
      region: 'haryana',
    },
    {
      value: 'guruvayur',
      label: 'Guruvayur',
      region: 'kerala',
    },
    {
      value: 'guwahati',
      label: 'Guwahati',
      region: 'assam',
    },
    {
      value: 'gwalior',
      label: 'Gwalior',
      region: 'madhya-pradesh',
    },
    {
      value: 'habra',
      label: 'Habra',
      region: 'west-bengal',
    },
    {
      value: 'hadapsar',
      label: 'Hadapsar',
      region: 'maharashtra',
    },
    {
      value: 'hajipur',
      label: 'Hajipur',
      region: 'bihar',
    },
    {
      value: 'haldia',
      label: 'Haldia',
      region: 'west-bengal',
    },
    {
      value: 'halisahar',
      label: 'Halisahar',
      region: 'west-bengal',
    },
    {
      value: 'hamirpur',
      label: 'Hamirpur',
      region: 'himachal-pradesh',
    },
    {
      value: 'hamirpur',
      label: 'Hamirpur',
      region: 'himachal-pradesh',
    },
    {
      value: 'hanumangarh',
      label: 'Hanumangarh',
      region: 'rajasthan',
    },
    {
      value: 'hapur',
      label: 'Hapur',
      region: 'uttar-pradesh',
    },
    {
      value: 'hardoi',
      label: 'Hardoi',
      region: 'uttar-pradesh',
    },
    {
      value: 'haridwar',
      label: 'Haridwar',
      region: 'uttarakhand',
    },
    {
      value: 'haripad',
      label: 'Haripad',
      region: 'kerala',
    },
    {
      value: 'harrington-road',
      label: 'Harrington Road',
      region: 'tamil-nadu',
    },
    {
      value: 'hassan',
      label: 'Hassan',
      region: 'karnataka',
    },
    {
      value: 'hathras',
      label: 'Hathras',
      region: 'uttar-pradesh',
    },
    {
      value: 'hauz-khas',
      label: 'Hauz Khas',
      region: 'delhi',
    },
    {
      value: 'haveri',
      label: 'Haveri',
      region: 'karnataka',
    },
    {
      value: 'hayathnagar',
      label: 'Hayathnagar',
      region: 'telangana',
    },
    {
      value: 'hazaribagh',
      label: 'Hazaribagh',
      region: 'jharkhand',
    },
    {
      value: 'hebbal',
      label: 'Hebbal',
      region: 'karnataka',
    },
    {
      value: 'himayatnagar',
      label: 'Himayatnagar',
      region: 'telangana',
    },
    {
      value: 'hindaun',
      label: 'Hindaun',
      region: 'rajasthan',
    },
    {
      value: 'hindupur',
      label: 'Hindupur',
      region: 'andhra-pradesh',
    },
    {
      value: 'hinganghat',
      label: 'Hinganghat',
      region: 'maharashtra',
    },
    {
      value: 'hinjewadi',
      label: 'Hinjewadi',
      region: 'maharashtra',
    },
    {
      value: 'hisar',
      label: 'Hisar',
      region: 'haryana',
    },
    {
      value: 'hitec-city',
      label: 'HITEC City',
      region: 'telangana',
    },
    {
      value: 'hooghly',
      label: 'Hooghly',
      region: 'west-bengal',
    },
    {
      value: 'horamavu',
      label: 'Horamavu',
      region: 'karnataka',
    },
    {
      value: 'hoshiarpur',
      label: 'Hoshiarpur',
      region: 'punjab',
    },
    {
      value: 'hospet',
      label: 'Hospet',
      region: 'karnataka',
    },
    {
      value: 'hosur',
      label: 'Hosur',
      region: 'tamil-nadu',
    },
    {
      value: 'howrah',
      label: 'Howrah',
      region: 'west-bengal',
    },
    {
      value: 'hoysala-nagar',
      label: 'Hoysala Nagar',
      region: 'karnataka',
    },
    {
      value: 'hsr',
      label: 'HSR',
      region: 'karnataka',
    },
    {
      value: 'hulimavu',
      label: 'Hulimavu',
      region: 'karnataka',
    },
    {
      value: 'hyderabad',
      label: 'Hyderabad',
      region: 'telangana',
    },
    {
      value: 'hyderguda',
      label: 'Hyderguda',
      region: 'telangana',
    },
    {
      value: 'ichalkaranji',
      label: 'Ichalkaranji',
      region: 'maharashtra',
    },
    {
      value: 'imphal',
      label: 'Imphal',
      region: 'manipur',
    },
    {
      value: 'indira-nagar',
      label: 'Indira Nagar',
      region: 'karnataka',
    },
    {
      value: 'indiranagar',
      label: 'Indiranagar',
      region: 'karnataka',
    },
    {
      value: 'indirapuram',
      label: 'Indirapuram',
      region: 'uttar-pradesh',
    },
    {
      value: 'indore',
      label: 'Indore',
      region: 'madhya-pradesh',
    },
    {
      value: 'irinjalakuda',
      label: 'Irinjalakuda',
      region: 'kerala',
    },
    {
      value: 'itanagar',
      label: 'Itanagar',
      region: 'arunachal-pradesh',
    },
    {
      value: 'itarsi',
      label: 'Itarsi',
      region: 'madhya-pradesh',
    },
    {
      value: 'jabalpur',
      label: 'Jabalpur',
      region: 'madhya-pradesh',
    },
    {
      value: 'jadavpur',
      label: 'Jadavpur',
      region: 'west-bengal',
    },
    {
      value: 'jagatpura',
      label: 'Jagatpura',
      region: 'rajasthan',
    },
    {
      value: 'jagdalpur',
      label: 'Jagdalpur',
      region: 'chhattisgarh',
    },
    {
      value: 'jagtial',
      label: 'Jagtial',
      region: 'telangana',
    },
    {
      value: 'jaipur',
      label: 'Jaipur',
      region: 'rajasthan',
    },
    {
      value: 'jaisalmer',
      label: 'Jaisalmer',
      region: 'rajasthan',
    },
    {
      value: 'jalahalli',
      label: 'Jalahalli',
      region: 'karnataka',
    },
    {
      value: 'jalandhar',
      label: 'Jalandhar',
      region: 'punjab',
    },
    {
      value: 'jalaun',
      label: 'Jalaun',
      region: 'uttar-pradesh',
    },
    {
      value: 'jalgaon',
      label: 'Jalgaon',
      region: 'maharashtra',
    },
    {
      value: 'jalna',
      label: 'Jalna',
      region: 'maharashtra',
    },
    {
      value: 'jalpaiguri',
      label: 'Jalpaiguri',
      region: 'west-bengal',
    },
    {
      value: 'jamalpur',
      label: 'Jamalpur',
      region: 'bihar',
    },
    {
      value: 'jammu',
      label: 'Jammu',
      region: 'jammu-kashmir',
    },
    {
      value: 'jamnagar',
      label: 'Jamnagar',
      region: 'gujarat',
    },
    {
      value: 'jamshedpur',
      label: 'Jamshedpur',
      region: 'jharkhand',
    },
    {
      value: 'janakpuri',
      label: 'Janakpuri',
      region: 'delhi',
    },
    {
      value: 'jangipur',
      label: 'Jangipur',
      region: 'west-bengal',
    },
    {
      value: 'jarganahalli',
      label: 'Jarganahalli',
      region: 'karnataka',
    },
    {
      value: 'jaunpur',
      label: 'Jaunpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'jayadeva',
      label: 'Jayadeva',
      region: 'karnataka',
    },
    {
      value: 'jayanagar',
      label: 'Jayanagar',
      region: 'karnataka',
    },
    {
      value: 'jehanabad',
      label: 'Jehanabad',
      region: 'bihar',
    },
    {
      value: 'jetpur',
      label: 'Jetpur',
      region: 'gujarat',
    },
    {
      value: 'jeypore',
      label: 'Jeypore',
      region: 'odisha',
    },
    {
      value: 'jhajjar',
      label: 'Jhajjar',
      region: 'haryana',
    },
    {
      value: 'jhalawar',
      label: 'Jhalawar',
      region: 'rajasthan',
    },
    {
      value: 'jhansi',
      label: 'Jhansi',
      region: 'uttar-pradesh',
    },
    {
      value: 'jharsuguda',
      label: 'Jharsuguda',
      region: 'odisha',
    },
    {
      value: 'jhotwara',
      label: 'Jhotwara',
      region: 'rajasthan',
    },
    {
      value: 'jhunjhunu',
      label: 'Jhunjhunu',
      region: 'rajasthan',
    },
    {
      value: 'jind',
      label: 'Jind',
      region: 'haryana',
    },
    {
      value: 'joda',
      label: 'Joda',
      region: 'odisha',
    },
    {
      value: 'jodhpur',
      label: 'Jodhpur',
      region: 'rajasthan',
    },
    {
      value: 'jogeshwari',
      label: 'Jogeshwari',
      region: 'maharashtra',
    },
    {
      value: 'jor-bagh',
      label: 'Jor Bagh',
      region: 'delhi',
    },
    {
      value: 'jorhat',
      label: 'Jorhat',
      region: 'assam',
    },
    {
      value: 'jp-nagar',
      label: 'JP Nagar',
      region: 'karnataka',
    },
    {
      value: 'jubilee-hills',
      label: 'Jubilee Hills',
      region: 'telangana',
    },
    {
      value: 'juhu',
      label: 'Juhu',
      region: 'maharashtra',
    },
    {
      value: 'junagadh',
      label: 'Junagadh',
      region: 'gujarat',
    },
    {
      value: 'k-k-nagar',
      label: 'K K Nagar',
      region: 'tamil-nadu',
    },
    {
      value: 'kadapa',
      label: 'Kadapa',
      region: 'andhra-pradesh',
    },
    {
      value: 'kaithal',
      label: 'Kaithal',
      region: 'haryana',
    },
    {
      value: 'kakinada',
      label: 'Kakinada',
      region: 'andhra-pradesh',
    },
    {
      value: 'kalahandi',
      label: 'Kalahandi',
      region: 'odisha',
    },
    {
      value: 'kalba-devi',
      label: 'Kalba Devi',
      region: 'maharashtra',
    },
    {
      value: 'kalkere',
      label: 'Kalkere',
      region: 'karnataka',
    },
    {
      value: 'kalol',
      label: 'Kalol',
      region: 'gujarat',
    },
    {
      value: 'kalpetta',
      label: 'Kalpetta',
      region: 'kerala',
    },
    {
      value: 'kalva',
      label: 'Kalva',
      region: 'maharashtra',
    },
    {
      value: 'kalyan',
      label: 'Kalyan',
      region: 'maharashtra',
    },
    {
      value: 'kalyan-nagar',
      label: 'Kalyan Nagar',
      region: 'karnataka',
    },
    {
      value: 'kalyani',
      label: 'Kalyani',
      region: 'west-bengal',
    },
    {
      value: 'kalyani-nagar',
      label: 'Kalyani Nagar',
      region: 'maharashtra',
    },
    {
      value: 'kamakshipalya',
      label: 'Kamakshipalya',
      region: 'karnataka',
    },
    {
      value: 'kamarhati',
      label: 'Kamarhati',
      region: 'west-bengal',
    },
    {
      value: 'kamla-nagar',
      label: 'Kamla Nagar',
      region: 'delhi',
    },
    {
      value: 'kammanahalli',
      label: 'Kammanahalli',
      region: 'karnataka',
    },
    {
      value: 'kamrej',
      label: 'Kamrej',
      region: 'gujarat',
    },
    {
      value: 'kanchipuram',
      label: 'Kanchipuram',
      region: 'tamil-nadu',
    },
    {
      value: 'kanchrapara',
      label: 'Kanchrapara',
      region: 'west-bengal',
    },
    {
      value: 'kandivali',
      label: 'Kandivali',
      region: 'maharashtra',
    },
    {
      value: 'kangra',
      label: 'Kangra',
      region: 'himachal-pradesh',
    },
    {
      value: 'kangra',
      label: 'Kangra',
      region: 'himachal-pradesh',
    },
    {
      value: 'kannur',
      label: 'Kannur',
      region: 'kerala',
    },
    {
      value: 'kanpur',
      label: 'Kanpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'kapra',
      label: 'Kapra',
      region: 'telangana',
    },
    {
      value: 'kapurthala',
      label: 'Kapurthala',
      region: 'punjab',
    },
    {
      value: 'karimnagar',
      label: 'Karimnagar',
      region: 'telangana',
    },
    {
      value: 'karnal',
      label: 'Karnal',
      region: 'haryana',
    },
    {
      value: 'karol-bagh',
      label: 'Karol Bagh',
      region: 'delhi',
    },
    {
      value: 'karur',
      label: 'Karur',
      region: 'tamil-nadu',
    },
    {
      value: 'kasaragod',
      label: 'Kasaragod',
      region: 'kerala',
    },
    {
      value: 'kasba',
      label: 'Kasba',
      region: 'west-bengal',
    },
    {
      value: 'kasganj',
      label: 'Kasganj',
      region: 'uttar-pradesh',
    },
    {
      value: 'kashipur',
      label: 'Kashipur',
      region: 'uttarakhand',
    },
    {
      value: 'kashmir',
      label: 'Kashmir',
      region: 'jammu-kashmir',
    },
    {
      value: 'kashmiri-gate',
      label: 'Kashmiri Gate',
      region: 'delhi',
    },
    {
      value: 'kasturba-road',
      label: 'Kasturba Road',
      region: 'karnataka',
    },
    {
      value: 'kathgodam',
      label: 'Kathgodam',
      region: 'uttarakhand',
    },
    {
      value: 'katihar',
      label: 'Katihar',
      region: 'bihar',
    },
    {
      value: 'katni',
      label: 'Katni',
      region: 'madhya-pradesh',
    },
    {
      value: 'katraj',
      label: 'Katraj',
      region: 'maharashtra',
    },
    {
      value: 'kattivakkam',
      label: 'Kattivakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'kavaratti',
      label: 'Kavaratti',
      region: 'lakshadweep',
    },
    {
      value: 'kazhipattur',
      label: 'Kazhipattur',
      region: 'tamil-nadu',
    },
    {
      value: 'keelkattalai',
      label: 'Keelkattalai',
      region: 'tamil-nadu',
    },
    {
      value: 'kengeri',
      label: 'Kengeri',
      region: 'karnataka',
    },
    {
      value: 'kerala',
      label: 'Kerala',
      region: 'kerala',
    },
    {
      value: 'idukki',
      label: 'Idukki',
      region: 'kerala',
    },

    {
      value: 'khadki',
      label: 'Khadki',
      region: 'maharashtra',
    },
    {
      value: 'khairatabad',
      label: 'Khairatabad',
      region: 'telangana',
    },
    {
      value: 'khammam',
      label: 'Khammam',
      region: 'telangana',
    },
    {
      value: 'khandala',
      label: 'Khandala',
      region: 'maharashtra',
    },
    {
      value: 'khandwa',
      label: 'Khandwa',
      region: 'madhya-pradesh',
    },
    {
      value: 'khanna',
      label: 'Khanna',
      region: 'punjab',
    },
    {
      value: 'khar',
      label: 'Khar',
      region: 'maharashtra',
    },
    {
      value: 'kharadi',
      label: 'Kharadi',
      region: 'maharashtra',
    },
    {
      value: 'kharagpur',
      label: 'Kharagpur',
      region: 'west-bengal',
    },
    {
      value: 'khardah',
      label: 'Khardah',
      region: 'west-bengal',
    },
    {
      value: 'khargone',
      label: 'Khargone',
      region: 'madhya-pradesh',
    },
    {
      value: 'khetwadi',
      label: 'Khetwadi',
      region: 'maharashtra',
    },
    {
      value: 'khidirpur',
      label: 'Khidirpur',
      region: 'west-bengal',
    },
    {
      value: 'khurja',
      label: 'Khurja',
      region: 'uttar-pradesh',
    },
    {
      value: 'kilpauk',
      label: 'Kilpauk',
      region: 'tamil-nadu',
    },
    {
      value: 'kishanganj',
      label: 'Kishanganj',
      region: 'bihar',
    },
    {
      value: 'kishangarh',
      label: 'Kishangarh',
      region: 'rajasthan',
    },
    {
      value: 'Kochi',
      label: 'Kochi',
      region: 'kerala',
    },
    {
      value: 'kodaikanal',
      label: 'Kodaikanal',
      region: 'tamil-nadu',
    },
    {
      value: 'kodambakkam',
      label: 'Kodambakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'kodungaiyur',
      label: 'Kodungaiyur',
      region: 'tamil-nadu',
    },
    {
      value: 'kodungallur',
      label: 'Kodungallur',
      region: 'kerala',
    },
    {
      value: 'kohima',
      label: 'Kohima',
      region: 'nagaland',
    },
    {
      value: 'kolar',
      label: 'Kolar',
      region: 'karnataka',
    },
    {
      value: 'kolhapur',
      label: 'Kolhapur',
      region: 'maharashtra',
    },
    {
      value: 'kolkata',
      label: 'Kolkata',
      region: 'west-bengal',
    },
    {
      value: 'kollam',
      label: 'Kollam',
      region: 'kerala',
    },
    {
      value: 'kolshet',
      label: 'Kolshet',
      region: 'maharashtra',
    },
    {
      value: 'kompally',
      label: 'Kompally',
      region: 'telangana',
    },
    {
      value: 'kondapur',
      label: 'Kondapur',
      region: 'telangana',
    },
    {
      value: 'kondhwa',
      label: 'Kondhwa',
      region: 'maharashtra',
    },
    {
      value: 'konnagar',
      label: 'Konnagar',
      region: 'west-bengal',
    },
    {
      value: 'kopar-khairane',
      label: 'Kopar Khairane',
      region: 'maharashtra',
    },
    {
      value: 'koppal',
      label: 'Koppal',
      region: 'karnataka',
    },
    {
      value: 'koramangala',
      label: 'Koramangala',
      region: 'karnataka',
    },
    {
      value: 'korattur',
      label: 'Korattur',
      region: 'tamil-nadu',
    },
    {
      value: 'korba',
      label: 'Korba',
      region: 'chhattisgarh',
    },
    {
      value: 'koregaon-park',
      label: 'Koregaon Park',
      region: 'maharashtra',
    },
    {
      value: 'korukkupet',
      label: 'Korukkupet',
      region: 'tamil-nadu',
    },
    {
      value: 'kosamba',
      label: 'Kosamba',
      region: 'gujarat',
    },
    {
      value: 'kota',
      label: 'Kota',
      region: 'rajasthan',
    },
    {
      value: 'kothamangalam',
      label: 'Kothamangalam',
      region: 'kerala',
    },
    {
      value: 'kotharia',
      label: 'Kotharia',
      region: 'gujarat',
    },
    {
      value: 'kothrud',
      label: 'Kothrud',
      region: 'maharashtra',
    },
    {
      value: 'kottakkal',
      label: 'Kottakkal',
      region: 'kerala',
    },
    {
      value: 'kottarakara',
      label: 'Kottarakara',
      region: 'kerala',
    },
    {
      value: 'kottayam',
      label: 'Kottayam',
      region: 'kerala',
    },
    {
      value: 'kottivakkam',
      label: 'Kottivakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'kottur',
      label: 'Kottur',
      region: 'tamil-nadu',
    },
    {
      value: 'kotturpuram',
      label: 'Kotturpuram',
      region: 'tamil-nadu',
    },
    {
      value: 'koyambedu',
      label: 'Koyambedu',
      region: 'tamil-nadu',
    },
    {
      value: 'kozhikode',
      label: 'Kozhikode',
      region: 'kerala',
    },
    {
      value: 'kr-puram',
      label: 'KR Puram',
      region: 'karnataka',
    },
    {
      value: 'krishnanagar',
      label: 'Krishnanagar',
      region: 'west-bengal',
    },
    {
      value: 'krishnapur',
      label: 'Krishnapur',
      region: 'west-bengal',
    },
    {
      value: 'kukatpally',
      label: 'Kukatpally',
      region: 'telangana',
    },
    {
      value: 'kullu',
      label: 'Kullu',
      region: 'himachal-pradesh',
    },
    {
      value: 'kullu',
      label: 'Kullu',
      region: 'himachal-pradesh',
    },
    {
      value: 'kumaraswamy-layout',
      label: 'Kumaraswamy Layout',
      region: 'karnataka',
    },
    {
      value: 'kumbakonam',
      label: 'Kumbakonam',
      region: 'tamil-nadu',
    },
    {
      value: 'kumhari',
      label: 'Kumhari',
      region: 'chhattisgarh',
    },
    {
      value: 'kundrathur',
      label: 'Kundrathur',
      region: 'tamil-nadu',
    },
    {
      value: 'kunnamkulam',
      label: 'Kunnamkulam',
      region: 'kerala',
    },
    {
      value: 'kurla',
      label: 'Kurla',
      region: 'maharashtra',
    },
    {
      value: 'kurnool',
      label: 'Kurnool',
      region: 'andhra-pradesh',
    },
    {
      value: 'kurukshetra',
      label: 'Kurukshetra',
      region: 'haryana',
    },
    {
      value: 'lajpat-nagar',
      label: 'Lajpat Nagar',
      region: 'delhi',
    },
    {
      value: 'lake-town',
      label: 'Lake Town',
      region: 'west-bengal',
    },
    {
      value: 'lakhimpur-kheri',
      label: 'Lakhimpur Kheri',
      region: 'uttar-pradesh',
    },
    {
      value: 'lalitpur',
      label: 'Lalitpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'latur',
      label: 'Latur',
      region: 'maharashtra',
    },
    {
      value: 'laxmi-nagar',
      label: 'Laxmi Nagar',
      region: 'delhi',
    },
    {
      value: 'lb-nagar',
      label: 'LB Nagar',
      region: 'telangana',
    },
    {
      value: 'leh',
      label: 'Leh',
      region: 'ladakh',
    },
    {
      value: 'lingarajapuram',
      label: 'Lingarajapuram',
      region: 'karnataka',
    },
    {
      value: 'lohegaon',
      label: 'Lohegaon',
      region: 'maharashtra',
    },
    {
      value: 'lokhandwala',
      label: 'Lokhandwala',
      region: 'maharashtra',
    },
    {
      value: 'lonavala',
      label: 'Lonavala',
      region: 'maharashtra',
    },
    {
      value: 'lower-parel',
      label: 'Lower Parel',
      region: 'maharashtra',
    },
    {
      value: 'lucknow',
      label: 'Lucknow',
      region: 'uttar-pradesh',
    },
    {
      value: 'ludhiana',
      label: 'Ludhiana',
      region: 'punjab',
    },
    {
      value: 'machilipatnam',
      label: 'Machilipatnam',
      region: 'andhra-pradesh',
    },
    {
      value: 'madambakkam',
      label: 'Madambakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'madanapalle',
      label: 'Madanapalle',
      region: 'andhra-pradesh',
    },
    {
      value: 'madh',
      label: 'Madh',
      region: 'maharashtra',
    },
    {
      value: 'madhapur',
      label: 'Madhapur',
      region: 'telangana',
    },
    {
      value: 'madhavaram',
      label: 'Madhavaram',
      region: 'tamil-nadu',
    },
    {
      value: 'madhyamgram',
      label: 'Madhyamgram',
      region: 'west-bengal',
    },
    {
      value: 'madipakkam',
      label: 'Madipakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'madivala',
      label: 'Madivala',
      region: 'karnataka',
    },
    {
      value: 'madiwala',
      label: 'Madiwala',
      region: 'karnataka',
    },
    {
      value: 'madurai',
      label: 'Madurai',
      region: 'tamil-nadu',
    },
    {
      value: 'maduravoyal',
      label: 'Maduravoyal',
      region: 'tamil-nadu',
    },
    {
      value: 'magarpatta',
      label: 'Magarpatta',
      region: 'maharashtra',
    },
    {
      value: 'mahadevapura',
      label: 'Mahadevapura',
      region: 'karnataka',
    },
    {
      value: 'mahalakshmi',
      label: 'Mahalakshmi',
      region: 'maharashtra',
    },
    {
      value: 'mahalaxmi',
      label: 'Mahalaxmi',
      region: 'maharashtra',
    },
    {
      value: 'mahasamund',
      label: 'Mahasamund',
      region: 'chhattisgarh',
    },
    {
      value: 'mahbubnagar',
      label: 'Mahbubnagar',
      region: 'telangana',
    },
    {
      value: 'mahendragarh',
      label: 'Mahendragarh',
      region: 'haryana',
    },
    {
      value: 'maheshtala',
      label: 'Maheshtala',
      region: 'west-bengal',
    },
    {
      value: 'mahim',
      label: 'Mahim',
      region: 'maharashtra',
    },
    {
      value: 'mahipalpur',
      label: 'Mahipalpur',
      region: 'delhi',
    },
    {
      value: 'mainpuri',
      label: 'Mainpuri',
      region: 'uttar-pradesh',
    },
    {
      value: 'makrana',
      label: 'Makrana',
      region: 'rajasthan',
    },
    {
      value: 'malad',
      label: 'Malad',
      region: 'maharashtra',
    },
    {
      value: 'malappuram',
      label: 'Malappuram',
      region: 'kerala',
    },
    {
      value: 'malda',
      label: 'Malda',
      region: 'west-bengal',
    },
    {
      value: 'malerkotla',
      label: 'Malerkotla',
      region: 'punjab',
    },
    {
      value: 'mali-munjeri',
      label: 'Mali Munjeri',
      region: 'maharashtra',
    },
    {
      value: 'malkajgiri',
      label: 'Malkajgiri',
      region: 'telangana',
    },
    {
      value: 'malleswaram',
      label: 'Malleswaram',
      region: 'karnataka',
    },
    {
      value: 'malviya-nagar',
      label: 'Malviya Nagar',
      region: 'delhi',
    },
    {
      value: 'mamulpet',
      label: 'Mamulpet',
      region: 'karnataka',
    },
    {
      value: 'mananthavady',
      label: 'Mananthavady',
      region: 'kerala',
    },
    {
      value: 'manapakkam',
      label: 'Manapakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'mandi',
      label: 'Mandi',
      region: 'himachal-pradesh',
    },
    {
      value: 'mandi',
      label: 'Mandi',
      region: 'himachal-pradesh',
    },
    {
      value: 'mandsaur',
      label: 'Mandsaur',
      region: 'madhya-pradesh',
    },
    {
      value: 'mandvi',
      label: 'Mandvi',
      region: 'maharashtra',
    },
    {
      value: 'mandya',
      label: 'Mandya',
      region: 'karnataka',
    },
    {
      value: 'mangadu',
      label: 'Mangadu',
      region: 'tamil-nadu',
    },
    {
      value: 'mangalagiri',
      label: 'Mangalagiri',
      region: 'andhra-pradesh',
    },
    {
      value: 'mangalore',
      label: 'Mangalore',
      region: 'karnataka',
    },
    {
      value: 'mangan',
      label: 'Mangan',
      region: 'sikkim',
    },
    {
      value: 'manikonda',
      label: 'Manikonda',
      region: 'telangana',
    },
    {
      value: 'manimajra',
      label: 'Manimajra',
      region: 'chandigarh',
    },
    {
      value: 'manjeri',
      label: 'Manjeri',
      region: 'kerala',
    },
    {
      value: 'mankhurd',
      label: 'Mankhurd',
      region: 'maharashtra',
    },
    {
      value: 'mansarovar',
      label: 'Mansarovar',
      region: 'rajasthan',
    },
    {
      value: 'mapusa',
      label: 'Mapusa',
      region: 'goa',
    },
    {
      value: 'marathahalli',
      label: 'Marathahalli',
      region: 'karnataka',
    },
    {
      value: 'margao',
      label: 'Margao',
      region: 'goa',
    },
    {
      value: 'marina-beach',
      label: 'Marina Beach',
      region: 'tamil-nadu',
    },
    {
      value: 'marine-lines',
      label: 'Marine Lines',
      region: 'maharashtra',
    },
    {
      value: 'mariyappanapalya',
      label: 'Mariyappanapalya',
      region: 'karnataka',
    },
    {
      value: 'mathikere',
      label: 'Mathikere',
      region: 'karnataka',
    },
    {
      value: 'mathura',
      label: 'Mathura',
      region: 'uttar-pradesh',
    },
    {
      value: 'mau',
      label: 'Mau',
      region: 'uttar-pradesh',
    },
    {
      value: 'mavelikkara',
      label: 'Mavelikkara',
      region: 'kerala',
    },
    {
      value: 'mayur-vihar',
      label: 'Mayur Vihar',
      region: 'delhi',
    },
    {
      value: 'medavakkam',
      label: 'Medavakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'medinipur',
      label: 'Medinipur',
      region: 'west-bengal',
    },
    {
      value: 'meenambakkam',
      label: 'Meenambakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'meerut',
      label: 'Meerut',
      region: 'uttar-pradesh',
    },
    {
      value: 'mehrauli',
      label: 'Mehrauli',
      region: 'delhi',
    },
    {
      value: 'mehsana',
      label: 'Mehsana',
      region: 'gujarat',
    },
    {
      value: 'mg-road',
      label: 'MG Road',
      region: 'karnataka',
    },
    {
      value: 'mgr-nagar',
      label: 'MGR Nagar',
      region: 'tamil-nadu',
    },
    {
      value: 'minjur',
      label: 'Minjur',
      region: 'tamil-nadu',
    },
    {
      value: 'mira-bhayandar',
      label: 'Mira Bhayandar',
      region: 'maharashtra',
    },
    {
      value: 'mira-road',
      label: 'Mira Road',
      region: 'maharashtra',
    },
    {
      value: 'miryalaguda',
      label: 'Miryalaguda',
      region: 'telangana',
    },
    {
      value: 'mirzapur',
      label: 'Mirzapur',
      region: 'uttar-pradesh',
    },
    {
      value: 'miyapur',
      label: 'Miyapur',
      region: 'telangana',
    },
    {
      value: 'mobor-beach',
      label: 'Mobor Beach',
      region: 'goa',
    },
    {
      value: 'model-town',
      label: 'Model Town',
      region: 'delhi',
    },
    {
      value: 'modinagar',
      label: 'Modinagar',
      region: 'uttar-pradesh',
    },
    {
      value: 'moga',
      label: 'Moga',
      region: 'punjab',
    },
    {
      value: 'mohali',
      label: 'Mohali',
      region: 'punjab',
    },
    {
      value: 'moolakadai',
      label: 'Moolakadai',
      region: 'tamil-nadu',
    },
    {
      value: 'moradabad',
      label: 'Moradabad',
      region: 'uttar-pradesh',
    },
    {
      value: 'morbi',
      label: 'Morbi',
      region: 'gujarat',
    },
    {
      value: 'morena',
      label: 'Morena',
      region: 'madhya-pradesh',
    },
    {
      value: 'mori-gate',
      label: 'Mori Gate',
      region: 'delhi',
    },
    {
      value: 'morjim-beach',
      label: 'Morjim Beach',
      region: 'goa',
    },
    {
      value: 'mormugao',
      label: 'Mormugao',
      region: 'goa',
    },
    {
      value: 'moti-bagh',
      label: 'Moti Bagh',
      region: 'delhi',
    },
    {
      value: 'motihari',
      label: 'Motihari',
      region: 'bihar',
    },
    {
      value: 'mount-abu',
      label: 'Mount Abu',
      region: 'rajasthan',
    },
    {
      value: 'mount-road',
      label: 'Mount Road',
      region: 'tamil-nadu',
    },
    {
      value: 'mudichur',
      label: 'Mudichur',
      region: 'tamil-nadu',
    },
    {
      value: 'mugalivakkam',
      label: 'Mugalivakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'mughalsarai',
      label: 'Mughalsarai',
      region: 'uttar-pradesh',
    },
    {
      value: 'muktsar',
      label: 'Muktsar',
      region: 'punjab',
    },
    {
      value: 'mulund',
      label: 'Mulund',
      region: 'maharashtra',
    },
    {
      value: 'mumbai',
      label: 'Mumbai',
      region: 'maharashtra',
    },
    {
      value: 'mumbai-central',
      label: 'Mumbai Central',
      region: 'maharashtra',
    },
    {
      value: 'mundhwa',
      label: 'Mundhwa',
      region: 'maharashtra',
    },
    {
      value: 'munger',
      label: 'Munger',
      region: 'bihar',
    },
    {
      value: 'munirka',
      label: 'Munirka',
      region: 'delhi',
    },
    {
      value: 'murshidabad',
      label: 'Murshidabad',
      region: 'west-bengal',
    },
    {
      value: 'muvattupuzha',
      label: 'Muvattupuzha',
      region: 'kerala',
    },
    {
      value: 'muzaffarnagar',
      label: 'Muzaffarnagar',
      region: 'uttar-pradesh',
    },
    {
      value: 'muzaffarpur',
      label: 'Muzaffarpur',
      region: 'bihar',
    },
    {
      value: 'mylapore',
      label: 'Mylapore',
      region: 'tamil-nadu',
    },
    {
      value: 'mysore',
      label: 'Mysore',
      region: 'karnataka',
    },
    {
      value: 'nadia',
      label: 'Nadia',
      region: 'west-bengal',
    },
    {
      value: 'nadiad',
      label: 'Nadiad',
      region: 'gujarat',
    },
    {
      value: 'nagaon',
      label: 'Nagaon',
      region: 'assam',
    },
    {
      value: 'nagapattinam',
      label: 'Nagapattinam',
      region: 'tamil-nadu',
    },
    {
      value: 'nagarbhavi',
      label: 'Nagarbhavi',
      region: 'karnataka',
    },
    {
      value: 'nagaur',
      label: 'Nagaur',
      region: 'rajasthan',
    },
    {
      value: 'nagda',
      label: 'Nagda',
      region: 'madhya-pradesh',
    },
    {
      value: 'nagercoil',
      label: 'Nagercoil',
      region: 'tamil-nadu',
    },
    {
      value: 'nagpur',
      label: 'Nagpur',
      region: 'maharashtra',
    },
    {
      value: 'nahur',
      label: 'Nahur',
      region: 'maharashtra',
    },
    {
      value: 'naihati',
      label: 'Naihati',
      region: 'west-bengal',
    },
    {
      value: 'nainital',
      label: 'Nainital',
      region: 'uttarakhand',
    },
    {
      value: 'nalanda',
      label: 'Nalanda',
      region: 'bihar',
    },
    {
      value: 'nalgonda',
      label: 'Nalgonda',
      region: 'telangana',
    },
    {
      value: 'nallagandla',
      label: 'Nallagandla',
      region: 'telangana',
    },
    {
      value: 'namakkal',
      label: 'Namakkal',
      region: 'tamil-nadu',
    },
    {
      value: 'namchi',
      label: 'Namchi',
      region: 'sikkim',
    },
    {
      value: 'nampally',
      label: 'Nampally',
      region: 'telangana',
    },
    {
      value: 'nandanam',
      label: 'Nandanam',
      region: 'tamil-nadu',
    },
    {
      value: 'nanded',
      label: 'Nanded',
      region: 'maharashtra',
    },
    {
      value: 'nandini-layout',
      label: 'Nandini Layout',
      region: 'karnataka',
    },
    {
      value: 'nandurbar',
      label: 'Nandurbar',
      region: 'maharashtra',
    },
    {
      value: 'nandyal',
      label: 'Nandyal',
      region: 'andhra-pradesh',
    },
    {
      value: 'narasaraopet',
      label: 'Narasaraopet',
      region: 'andhra-pradesh',
    },
    {
      value: 'narela',
      label: 'Narela',
      region: 'delhi',
    },
    {
      value: 'nariman-point',
      label: 'Nariman Point',
      region: 'maharashtra',
    },
    {
      value: 'naroda',
      label: 'Naroda',
      region: 'gujarat',
    },
    {
      value: 'nashik',
      label: 'Nashik',
      region: 'maharashtra',
    },
    {
      value: 'nathdwara',
      label: 'Nathdwara',
      region: 'rajasthan',
    },
    {
      value: 'navi-mumbai',
      label: 'Navi Mumbai',
      region: 'maharashtra',
    },
    {
      value: 'navin-new-sama',
      label: 'Navin New Sama',
      region: 'gujarat',
    },
    {
      value: 'navsari',
      label: 'Navsari',
      region: 'gujarat',
    },
    {
      value: 'nawada',
      label: 'Nawada',
      region: 'bihar',
    },
    {
      value: 'nawalgarh',
      label: 'Nawalgarh',
      region: 'rajasthan',
    },
    {
      value: 'nayandahalli',
      label: 'Nayandahalli',
      region: 'karnataka',
    },
    {
      value: 'neelankarai',
      label: 'Neelankarai',
      region: 'tamil-nadu',
    },
    {
      value: 'neemuch',
      label: 'Neemuch',
      region: 'madhya-pradesh',
    },
    {
      value: 'nehru-place',
      label: 'Nehru Place',
      region: 'delhi',
    },
    {
      value: 'nellore',
      label: 'Nellore',
      region: 'andhra-pradesh',
    },
    {
      value: 'nesapakkam',
      label: 'Nesapakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'new-alipore',
      label: 'New Alipore',
      region: 'west-bengal',
    },
    {
      value: 'new-market',
      label: 'New Market',
      region: 'west-bengal',
    },
    {
      value: 'newtown',
      label: 'Newtown',
      region: 'west-bengal',
    },
    {
      value: 'nilambur',
      label: 'Nilambur',
      region: 'kerala',
    },
    {
      value: 'nizamabad',
      label: 'Nizamabad',
      region: 'telangana',
    },
    {
      value: 'noida',
      label: 'Noida',
      region: 'uttar-pradesh',
    },
    {
      value: 'north-24-parganas',
      label: 'North 24 Parganas',
      region: 'west-bengal',
    },
    {
      value: 'nungambakkam',
      label: 'Nungambakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'ongole',
      label: 'Ongole',
      region: 'andhra-pradesh',
    },
    {
      value: 'ooty',
      label: 'Ooty',
      region: 'tamil-nadu',
    },
    {
      value: 'orai',
      label: 'Orai',
      region: 'uttar-pradesh',
    },
    {
      value: 'osmanabad',
      label: 'Osmanabad',
      region: 'maharashtra',
    },
    {
      value: 'ottappalam',
      label: 'Ottappalam',
      region: 'kerala',
    },
    {
      value: 'otteri',
      label: 'Otteri',
      region: 'tamil-nadu',
    },
    {
      value: 'pahalgam',
      label: 'Pahalgam',
      region: 'jammu-kashmir',
    },
    {
      value: 'paharganj',
      label: 'Paharganj',
      region: 'delhi',
    },
    {
      value: 'pakkam',
      label: 'Pakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'palakkad',
      label: 'Palakkad',
      region: 'kerala',
    },
    {
      value: 'palakollu',
      label: 'Palakollu',
      region: 'andhra-pradesh',
    },
    {
      value: 'palampur',
      label: 'Palampur',
      region: 'himachal-pradesh',
    },
    {
      value: 'palampur',
      label: 'Palampur',
      region: 'himachal-pradesh',
    },
    {
      value: 'palanpur',
      label: 'Palanpur',
      region: 'gujarat',
    },
    {
      value: 'pali',
      label: 'Pali',
      region: 'rajasthan',
    },
    {
      value: 'pallavaram',
      label: 'Pallavaram',
      region: 'tamil-nadu',
    },
    {
      value: 'pallikaranai',
      label: 'Pallikaranai',
      region: 'tamil-nadu',
    },
    {
      value: 'palolem-beach',
      label: 'Palolem Beach',
      region: 'goa',
    },
    {
      value: 'panaji',
      label: 'Panaji',
      region: 'goa',
    },
    {
      value: 'panchkula',
      label: 'Panchkula',
      region: 'haryana',
    },
    {
      value: 'panihati',
      label: 'Panihati',
      region: 'west-bengal',
    },
    {
      value: 'panipat',
      label: 'Panipat',
      region: 'haryana',
    },
    {
      value: 'panvel',
      label: 'Panvel',
      region: 'maharashtra',
    },
    {
      value: 'parappanangadi',
      label: 'Parappanangadi',
      region: 'kerala',
    },
    {
      value: 'paravur',
      label: 'Paravur',
      region: 'kerala',
    },
    {
      value: 'parbhani',
      label: 'Parbhani',
      region: 'maharashtra',
    },
    {
      value: 'parel',
      label: 'Parel',
      region: 'maharashtra',
    },
    {
      value: 'park-street',
      label: 'Park Street',
      region: 'west-bengal',
    },
    {
      value: 'park-town',
      label: 'Park Town',
      region: 'tamil-nadu',
    },
    {
      value: 'paschim-vihar',
      label: 'Paschim Vihar',
      region: 'delhi',
    },
    {
      value: 'pashan',
      label: 'Pashan',
      region: 'maharashtra',
    },
    {
      value: 'pasighat',
      label: 'Pasighat',
      region: 'arunachal-pradesh',
    },
    {
      value: 'patan',
      label: 'Patan',
      region: 'gujarat',
    },
    {
      value: 'patancheru',
      label: 'Patancheru',
      region: 'telangana',
    },
    {
      value: 'pathanamthitta',
      label: 'Pathanamthitta',
      region: 'kerala',
    },
    {
      value: 'pathankot',
      label: 'Pathankot',
      region: 'punjab',
    },
    {
      value: 'patiala',
      label: 'Patiala',
      region: 'punjab',
    },
    {
      value: 'patna',
      label: 'Patna',
      region: 'bihar',
    },
    {
      value: 'pattabiram',
      label: 'Pattabiram',
      region: 'tamil-nadu',
    },
    {
      value: 'pattambi',
      label: 'Pattambi',
      region: 'kerala',
    },
    {
      value: 'pattaravakkam',
      label: 'Pattaravakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'pattravakkam',
      label: 'Pattravakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'payyanur',
      label: 'Payyanur',
      region: 'kerala',
    },
    {
      value: 'peenya',
      label: 'Peenya',
      region: 'karnataka',
    },
    {
      value: 'perambur',
      label: 'Perambur',
      region: 'tamil-nadu',
    },
    {
      value: 'pernem',
      label: 'Pernem',
      region: 'goa',
    },
    {
      value: 'perumbakkam',
      label: 'Perumbakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'perumbavoor',
      label: 'Perumbavoor',
      region: 'kerala',
    },
    {
      value: 'perungudi',
      label: 'Perungudi',
      region: 'tamil-nadu',
    },
    {
      value: 'phagwara',
      label: 'Phagwara',
      region: 'punjab',
    },
    {
      value: 'phool-bagan',
      label: 'Phool Bagan',
      region: 'west-bengal',
    },
    {
      value: 'pilibhit',
      label: 'Pilibhit',
      region: 'uttar-pradesh',
    },
    {
      value: 'pimpri-chinchwad',
      label: 'Pimpri Chinchwad',
      region: 'maharashtra',
    },
    {
      value: 'pirangut',
      label: 'Pirangut',
      region: 'maharashtra',
    },
    {
      value: 'pitampura',
      label: 'Pitampura',
      region: 'delhi',
    },
    {
      value: 'pithampur',
      label: 'Pithampur',
      region: 'madhya-pradesh',
    },
    {
      value: 'polichalur',
      label: 'Polichalur',
      region: 'tamil-nadu',
    },
    {
      value: 'pollachi',
      label: 'Pollachi',
      region: 'tamil-nadu',
    },
    {
      value: 'ponda',
      label: 'Ponda',
      region: 'goa',
    },
    {
      value: 'pondicherry',
      label: 'Pondicherry',
      region: 'pondicherry',
    },
    {
      value: 'pondy-bazaar',
      label: 'Pondy Bazaar',
      region: 'tamil-nadu',
    },
    {
      value: 'ponnani',
      label: 'Ponnani',
      region: 'kerala',
    },
    {
      value: 'poonamallee',
      label: 'Poonamallee',
      region: 'tamil-nadu',
    },
    {
      value: 'porbandar',
      label: 'Porbandar',
      region: 'gujarat',
    },
    {
      value: 'port-blair',
      label: 'Port Blair',
      region: 'andaman-and-nicobar',
    },
    {
      value: 'porur',
      label: 'Porur',
      region: 'tamil-nadu',
    },
    {
      value: 'powai',
      label: 'Powai',
      region: 'maharashtra',
    },
    {
      value: 'prabhadevi',
      label: 'Prabhadevi',
      region: 'maharashtra',
    },
    {
      value: 'prayagraj',
      label: 'Prayagraj',
      region: 'uttar-pradesh',
    },
    {
      value: 'proddatur',
      label: 'Proddatur',
      region: 'andhra-pradesh',
    },
    {
      value: 'pudukkottai',
      label: 'Pudukkottai',
      region: 'tamil-nadu',
    },
    {
      value: 'punalur',
      label: 'Punalur',
      region: 'kerala',
    },
    {
      value: 'pune',
      label: 'Pune',
      region: 'maharashtra',
    },
    {
      value: 'puri',
      label: 'Puri',
      region: 'odisha',
    },
    {
      value: 'purnia',
      label: 'Purnia',
      region: 'bihar',
    },
    {
      value: 'purulia',
      label: 'Purulia',
      region: 'west-bengal',
    },
    {
      value: 'pushkar',
      label: 'Pushkar',
      region: 'rajasthan',
    },
    {
      value: 'quepem',
      label: 'Quepem',
      region: 'goa',
    },
    {
      value: 'quthbullapur',
      label: 'Quthbullapur',
      region: 'telangana',
    },
    {
      value: 'raebareli',
      label: 'Raebareli',
      region: 'uttar-pradesh',
    },
    {
      value: 'raichur',
      label: 'Raichur',
      region: 'karnataka',
    },
    {
      value: 'raigad',
      label: 'Raigad',
      region: 'maharashtra',
    },
    {
      value: 'raiganj',
      label: 'Raiganj',
      region: 'west-bengal',
    },
    {
      value: 'raigarh',
      label: 'Raigarh',
      region: 'chhattisgarh',
    },
    {
      value: 'raipur',
      label: 'Raipur',
      region: 'chhattisgarh',
    },
    {
      value: 'rajahmundry',
      label: 'Rajahmundry',
      region: 'andhra-pradesh',
    },
    {
      value: 'rajajinagar',
      label: 'Rajajinagar',
      region: 'karnataka',
    },
    {
      value: 'rajampet',
      label: 'Rajampet',
      region: 'andhra-pradesh',
    },
    {
      value: 'rajapalayam',
      label: 'Rajapalayam',
      region: 'tamil-nadu',
    },
    {
      value: 'rajarajeshwari-nagar',
      label: 'Rajarajeshwari Nagar',
      region: 'karnataka',
    },
    {
      value: 'rajarhat',
      label: 'Rajarhat',
      region: 'west-bengal',
    },
    {
      value: 'rajkot',
      label: 'Rajkot',
      region: 'gujarat',
    },
    {
      value: 'rajpura',
      label: 'Rajpura',
      region: 'punjab',
    },
    {
      value: 'ramagundam',
      label: 'Ramagundam',
      region: 'telangana',
    },
    {
      value: 'ramamurthy-nagar',
      label: 'Ramamurthy Nagar',
      region: 'karnataka',
    },
    {
      value: 'ramanattukara',
      label: 'Ramanattukara',
      region: 'kerala',
    },
    {
      value: 'ramgarh',
      label: 'Ramgarh',
      region: 'jharkhand',
    },
    {
      value: 'rampur',
      label: 'Rampur',
      region: 'uttar-pradesh',
    },
    {
      value: 'ranaghat',
      label: 'Ranaghat',
      region: 'west-bengal',
    },
    {
      value: 'ranchi',
      label: 'Ranchi',
      region: 'jharkhand',
    },
    {
      value: 'rander',
      label: 'Rander',
      region: 'gujarat',
    },
    {
      value: 'ranebennur',
      label: 'Ranebennur',
      region: 'karnataka',
    },
    {
      value: 'rangia',
      label: 'Rangia',
      region: 'assam',
    },
    {
      value: 'ratanpur',
      label: 'Ratanpur',
      region: 'chhattisgarh',
    },
    {
      value: 'ratlam',
      label: 'Ratlam',
      region: 'madhya-pradesh',
    },
    {
      value: 'ratnagiri',
      label: 'Ratnagiri',
      region: 'maharashtra',
    },
    {
      value: 'red-hills',
      label: 'Red Hills',
      region: 'tamil-nadu',
    },
    {
      value: 'rewa',
      label: 'Rewa',
      region: 'madhya-pradesh',
    },
    {
      value: 'rewari',
      label: 'Rewari',
      region: 'haryana',
    },
    {
      value: 'richmond-town',
      label: 'Richmond Town',
      region: 'karnataka',
    },
    {
      value: 'rishikesh',
      label: 'Rishikesh',
      region: 'uttarakhand',
    },
    {
      value: 'rishra',
      label: 'Rishra',
      region: 'west-bengal',
    },
    {
      value: 'rohini',
      label: 'Rohini',
      region: 'delhi',
    },
    {
      value: 'rohtak',
      label: 'Rohtak',
      region: 'haryana',
    },
    {
      value: 'rohtas',
      label: 'Rohtas',
      region: 'bihar',
    },
    {
      value: 'roorkee',
      label: 'Roorkee',
      region: 'uttarakhand',
    },
    {
      value: 'rourkela',
      label: 'Rourkela',
      region: 'odisha',
    },
    {
      value: 'royapettah',
      label: 'Royapettah',
      region: 'tamil-nadu',
    },
    {
      value: 'royapuram',
      label: 'Royapuram',
      region: 'tamil-nadu',
    },
    {
      value: 'rt-nagar',
      label: 'RT Nagar',
      region: 'karnataka',
    },
    {
      value: 'rudrapur',
      label: 'Rudrapur',
      region: 'uttarakhand',
    },
    {
      value: 'rupnagar',
      label: 'Rupnagar',
      region: 'punjab',
    },
    {
      value: 'sabarmati',
      label: 'Sabarmati',
      region: 'gujarat',
    },
    {
      value: 'sadar-bazar',
      label: 'Sadar Bazar',
      region: 'delhi',
    },
    {
      value: 'sadar-bazar',
      label: 'Sadar Bazar',
      region: 'delhi',
    },
    {
      value: 'sadashivanagar',
      label: 'Sadashivanagar',
      region: 'karnataka',
    },
    {
      value: 'safdarjung-enclave',
      label: 'Safdarjung Enclave',
      region: 'delhi',
    },
    {
      value: 'sagar',
      label: 'Sagar',
      region: 'madhya-pradesh',
    },
    {
      value: 'saharanpur',
      label: 'Saharanpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'saharsa',
      label: 'Saharsa',
      region: 'bihar',
    },
    {
      value: 'saidapet',
      label: 'Saidapet',
      region: 'tamil-nadu',
    },
    {
      value: 'saket',
      label: 'Saket',
      region: 'delhi',
    },
    {
      value: 'salabatpura',
      label: 'Salabatpura',
      region: 'gujarat',
    },
    {
      value: 'salem',
      label: 'Salem',
      region: 'tamil-nadu',
    },
    {
      value: 'saligramam',
      label: 'Saligramam',
      region: 'tamil-nadu',
    },
    {
      value: 'salt-lake',
      label: 'Salt Lake',
      region: 'west-bengal',
    },
    {
      value: 'sambalpur',
      label: 'Sambalpur',
      region: 'odisha',
    },
    {
      value: 'sambhal',
      label: 'Sambhal',
      region: 'uttar-pradesh',
    },
    {
      value: 'sanand',
      label: 'Sanand',
      region: 'gujarat',
    },
    {
      value: 'sandhurst-road',
      label: 'Sandhurst Road',
      region: 'maharashtra',
    },
    {
      value: 'sangam-vihar',
      label: 'Sangam Vihar',
      region: 'delhi',
    },
    {
      value: 'sangamwadi',
      label: 'Sangamwadi',
      region: 'maharashtra',
    },
    {
      value: 'sanganer',
      label: 'Sanganer',
      region: 'rajasthan',
    },
    {
      value: 'sangli',
      label: 'Sangli',
      region: 'maharashtra',
    },
    {
      value: 'sangrur',
      label: 'Sangrur',
      region: 'punjab',
    },
    {
      value: 'sanguem',
      label: 'Sanguem',
      region: 'goa',
    },
    {
      value: 'sanquelim',
      label: 'Sanquelim',
      region: 'goa',
    },
    {
      value: 'santacruz',
      label: 'Santacruz',
      region: 'maharashtra',
    },
    {
      value: 'sarai-rohilla',
      label: 'Sarai Rohilla',
      region: 'delhi',
    },
    {
      value: 'sarjapur',
      label: 'Sarjapur',
      region: 'karnataka',
    },
    {
      value: 'sarkhej',
      label: 'Sarkhej',
      region: 'gujarat',
    },
    {
      value: 'saroornagar',
      label: 'Saroornagar',
      region: 'telangana',
    },
    {
      value: 'sasaram',
      label: 'Sasaram',
      region: 'bihar',
    },
    {
      value: 'satara',
      label: 'Satara',
      region: 'maharashtra',
    },
    {
      value: 'satna',
      label: 'Satna',
      region: 'madhya-pradesh',
    },
    {
      value: 'saudagar',
      label: 'Saudagar',
      region: 'maharashtra',
    },
    {
      value: 'sawai-madhopur',
      label: 'Sawai Madhopur',
      region: 'rajasthan',
    },
    {
      value: 'sealdah',
      label: 'Sealdah',
      region: 'west-bengal',
    },
    {
      value: 'secunderabad',
      label: 'Secunderabad',
      region: 'telangana',
    },
    {
      value: 'sehore',
      label: 'Sehore',
      region: 'madhya-pradesh',
    },
    {
      value: 'selaiyur',
      label: 'Selaiyur',
      region: 'tamil-nadu',
    },
    {
      value: 'senapati-bapat-road',
      label: 'Senapati Bapat Road',
      region: 'maharashtra',
    },
    {
      value: 'seoni',
      label: 'Seoni',
      region: 'madhya-pradesh',
    },
    {
      value: 'serilingampally',
      label: 'Serilingampally',
      region: 'telangana',
    },
    {
      value: 'seshadripuram',
      label: 'Seshadripuram',
      region: 'karnataka',
    },
    {
      value: 'sewri',
      label: 'Sewri',
      region: 'maharashtra',
    },
    {
      value: 'shahdara',
      label: 'Shahdara',
      region: 'delhi',
    },
    {
      value: 'shahjahanpur',
      label: 'Shahjahanpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'shahpur',
      label: 'Shahpur',
      region: 'gujarat',
    },
    {
      value: 'shakti-nagar',
      label: 'Shakti Nagar',
      region: 'delhi',
    },
    {
      value: 'shalimar-bagh',
      label: 'Shalimar Bagh',
      region: 'delhi',
    },
    {
      value: 'shamli',
      label: 'Shamli',
      region: 'uttar-pradesh',
    },
    {
      value: 'shamshabad',
      label: 'Shamshabad',
      region: 'telangana',
    },
    {
      value: 'shantipur',
      label: 'Shantipur',
      region: 'west-bengal',
    },
    {
      value: 'shenoy-nagar',
      label: 'Shenoy Nagar',
      region: 'tamil-nadu',
    },
    {
      value: 'shikohabad',
      label: 'Shikohabad',
      region: 'uttar-pradesh',
    },
    {
      value: 'shillong',
      label: 'Shillong',
      region: 'meghalaya',
    },
    {
      value: 'shimla',
      label: 'Shimla',
      region: 'himachal-pradesh',
    },
    {
      value: 'shimla',
      label: 'Shimla',
      region: 'himachal-pradesh',
    },
    {
      value: 'shimoga',
      label: 'Shimoga',
      region: 'karnataka',
    },
    {
      value: 'shivaji-nagar',
      label: 'Shivaji Nagar',
      region: 'maharashtra',
    },
    {
      value: 'shivajinagar',
      label: 'Shivajinagar',
      region: 'karnataka',
    },
    {
      value: 'shivane',
      label: 'Shivane',
      region: 'maharashtra',
    },
    {
      value: 'shivpuri',
      label: 'Shivpuri',
      region: 'madhya-pradesh',
    },
    {
      value: 'sholavaram',
      label: 'Sholavaram',
      region: 'tamil-nadu',
    },
    {
      value: 'sholinganallur',
      label: 'Sholinganallur',
      region: 'tamil-nadu',
    },
    {
      value: 'siddipet',
      label: 'Siddipet',
      region: 'telangana',
    },
    {
      value: 'sikar',
      label: 'Sikar',
      region: 'rajasthan',
    },
    {
      value: 'silchar',
      label: 'Silchar',
      region: 'assam',
    },
    {
      value: 'siliguri',
      label: 'Siliguri',
      region: 'west-bengal',
    },
    {
      value: 'silvassa',
      label: 'Silvassa',
      region: 'dadra-nagar-haveli',
    },
    {
      value: 'singrauli',
      label: 'Singrauli',
      region: 'madhya-pradesh',
    },
    {
      value: 'sinquerim-beach',
      label: 'Sinquerim Beach',
      region: 'goa',
    },
    {
      value: 'sirsa',
      label: 'Sirsa',
      region: 'haryana',
    },
    {
      value: 'sitamarhi',
      label: 'Sitamarhi',
      region: 'bihar',
    },
    {
      value: 'sitapur',
      label: 'Sitapur',
      region: 'uttar-pradesh',
    },
    {
      value: 'sivaganga',
      label: 'Sivaganga',
      region: 'tamil-nadu',
    },
    {
      value: 'sivakasi',
      label: 'Sivakasi',
      region: 'tamil-nadu',
    },
    {
      value: 'siwan',
      label: 'Siwan',
      region: 'bihar',
    },
    {
      value: 'solan',
      label: 'Solan',
      region: 'himachal-pradesh',
    },
    {
      value: 'solan',
      label: 'Solan',
      region: 'himachal-pradesh',
    },
    {
      value: 'solapur',
      label: 'Solapur',
      region: 'maharashtra',
    },
    {
      value: 'somajiguda',
      label: 'Somajiguda',
      region: 'telangana',
    },
    {
      value: 'sonamarg',
      label: 'Sonamarg',
      region: 'jammu-kashmir',
    },
    {
      value: 'sonipat',
      label: 'Sonipat',
      region: 'haryana',
    },
    {
      value: 'south-24-parganas',
      label: 'South 24 Parganas',
      region: 'west-bengal',
    },
    {
      value: 'south-extension',
      label: 'South Extension',
      region: 'delhi',
    },
    {
      value: 'sowcarpet',
      label: 'Sowcarpet',
      region: 'tamil-nadu',
    },
    {
      value: 'sr-nagar',
      label: 'SR Nagar',
      region: 'telangana',
    },
    {
      value: 'sri-ganganagar',
      label: 'Sri Ganganagar',
      region: 'rajasthan',
    },
    {
      value: 'srikakulam',
      label: 'Srikakulam',
      region: 'andhra-pradesh',
    },
    {
      value: 'srinagar',
      label: 'Srinagar',
      region: 'jammu-kashmir',
    },
    {
      value: 'sriperumbudur',
      label: 'Sriperumbudur',
      region: 'tamil-nadu',
    },
    {
      value: 'sultan-palya',
      label: 'Sultan Palya',
      region: 'karnataka',
    },
    {
      value: 'sultanpur',
      label: 'Sultanpur',
      region: 'uttar-pradesh',
    },
    {
      value: 'sunam',
      label: 'Sunam',
      region: 'punjab',
    },
    {
      value: 'surat',
      label: 'Surat',
      region: 'gujarat',
    },
    {
      value: 'surendranagar',
      label: 'Surendranagar',
      region: 'gujarat',
    },
    {
      value: 'suryapet',
      label: 'Suryapet',
      region: 'telangana',
    },
    {
      value: 'swargate',
      label: 'Swargate',
      region: 'maharashtra',
    },
    {
      value: 't-nagar',
      label: 'T Nagar',
      region: 'tamil-nadu',
    },
    {
      value: 'tadepalligudem',
      label: 'Tadepalligudem',
      region: 'andhra-pradesh',
    },
    {
      value: 'tagore-park',
      label: 'Tagore Park',
      region: 'west-bengal',
    },
    {
      value: 'talcher',
      label: 'Talcher',
      region: 'odisha',
    },
    {
      value: 'taliparamba',
      label: 'Taliparamba',
      region: 'kerala',
    },
    {
      value: 'tambaram',
      label: 'Tambaram',
      region: 'tamil-nadu',
    },
    {
      value: 'tanda',
      label: 'Tanda',
      region: 'uttar-pradesh',
    },
    {
      value: 'tangra',
      label: 'Tangra',
      region: 'west-bengal',
    },
    {
      value: 'tanur',
      label: 'Tanur',
      region: 'kerala',
    },
    {
      value: 'taramani',
      label: 'Taramani',
      region: 'tamil-nadu',
    },
    {
      value: 'tardeo',
      label: 'Tardeo',
      region: 'maharashtra',
    },
    {
      value: 'tawang',
      label: 'Tawang',
      region: 'arunachal-pradesh',
    },
    {
      value: 'tenali',
      label: 'Tenali',
      region: 'andhra-pradesh',
    },
    {
      value: 'teynampet',
      label: 'Teynampet',
      region: 'tamil-nadu',
    },
    {
      value: 'tezpur',
      label: 'Tezpur',
      region: 'assam',
    },
    {
      value: 'thalassery',
      label: 'Thalassery',
      region: 'kerala',
    },
    {
      value: 'thane',
      label: 'Thane',
      region: 'maharashtra',
    },
    {
      value: 'thanesar',
      label: 'Thanesar',
      region: 'haryana',
    },
    {
      value: 'thanjavur',
      label: 'Thanjavur',
      region: 'tamil-nadu',
    },
    {
      value: 'thirumangalam',
      label: 'Thirumangalam',
      region: 'tamil-nadu',
    },
    {
      value: 'thirumullaivoyal',
      label: 'Thirumullaivoyal',
      region: 'tamil-nadu',
    },
    {
      value: 'thiruvalluvar',
      label: 'Thiruvalluvar',
      region: 'tamil-nadu',
    },
    {
      value: 'thiruvananthapuram',
      label: 'Thiruvananthapuram',
      region: 'kerala',
    },
    {
      value: 'thoothukudi',
      label: 'Thoothukudi',
      region: 'tamil-nadu',
    },
    {
      value: 'thoraipakkam',
      label: 'Thoraipakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'thrissur',
      label: 'Thrissur',
      region: 'kerala',
    },
    {
      value: 'timarpur',
      label: 'Timarpur',
      region: 'delhi',
    },
    {
      value: 'tinsukia',
      label: 'Tinsukia',
      region: 'assam',
    },
    {
      value: 'tiruchchirappalli',
      label: 'Tiruchchirappalli',
      region: 'tamil-nadu',
    },
    {
      value: 'tiruchirappalli',
      label: 'Tiruchirappalli',
      region: 'tamil-nadu',
    },
    {
      value: 'tirumala',
      label: 'Tirumala',
      region: 'andhra-pradesh',
    },
    {
      value: 'tirunelveli',
      label: 'Tirunelveli',
      region: 'tamil-nadu',
    },
    {
      value: 'tirupathur',
      label: 'Tirupathur',
      region: 'tamil-nadu',
    },
    {
      value: 'tirupati',
      label: 'Tirupati',
      region: 'andhra-pradesh',
    },
    {
      value: 'tiruppur',
      label: 'Tiruppur',
      region: 'tamil-nadu',
    },
    {
      value: 'tirur',
      label: 'Tirur',
      region: 'kerala',
    },
    {
      value: 'tiruvalla',
      label: 'Tiruvalla',
      region: 'kerala',
    },
    {
      value: 'tiruvannamalai',
      label: 'Tiruvannamalai',
      region: 'tamil-nadu',
    },
    {
      value: 'tiruvottiyur',
      label: 'Tiruvottiyur',
      region: 'tamil-nadu',
    },
    {
      value: 'tis-hazari',
      label: 'Tis Hazari',
      region: 'delhi',
    },
    {
      value: 'titagarh',
      label: 'Titagarh',
      region: 'west-bengal',
    },
    {
      value: 'tollygunge',
      label: 'Tollygunge',
      region: 'west-bengal',
    },
    {
      value: 'tondiarpet',
      label: 'Tondiarpet',
      region: 'tamil-nadu',
    },
    {
      value: 'tonk',
      label: 'Tonk',
      region: 'rajasthan',
    },
    {
      value: 'tonk-road',
      label: 'Tonk Road',
      region: 'rajasthan',
    },
    {
      value: 'triplicane',
      label: 'Triplicane',
      region: 'tamil-nadu',
    },
    {
      value: 'tumkur',
      label: 'Tumkur',
      region: 'karnataka',
    },
    {
      value: 'udaipur',
      label: 'Udaipur',
      region: 'rajasthan',
    },
    {
      value: 'udgir',
      label: 'Udgir',
      region: 'maharashtra',
    },
    {
      value: 'udupi',
      label: 'Udupi',
      region: 'karnataka',
    },
    {
      value: 'ujhani',
      label: 'Ujhani',
      region: 'uttar-pradesh',
    },
    {
      value: 'ujjain',
      label: 'Ujjain',
      region: 'madhya-pradesh',
    },
    {
      value: 'ulhasnagar',
      label: 'Ulhasnagar',
      region: 'maharashtra',
    },
    {
      value: 'ulsoor',
      label: 'Ulsoor',
      region: 'karnataka',
    },
    {
      value: 'uluberia',
      label: 'Uluberia',
      region: 'west-bengal',
    },
    {
      value: 'una',
      label: 'Una',
      region: 'himachal-pradesh',
    },
    {
      value: 'una',
      label: 'Una',
      region: 'himachal-pradesh',
    },
    {
      value: 'unnao',
      label: 'Unnao',
      region: 'uttar-pradesh',
    },
    {
      value: 'uppal',
      label: 'Uppal',
      region: 'telangana',
    },
    {
      value: 'uppal-kalan',
      label: 'Uppal Kalan',
      region: 'telangana',
    },
    {
      value: 'utran',
      label: 'Utran',
      region: 'gujarat',
    },
    {
      value: 'uttam-nagar',
      label: 'Uttam Nagar',
      region: 'delhi',
    },
    {
      value: 'uttarahalli',
      label: 'Uttarahalli',
      region: 'karnataka',
    },
    {
      value: 'uttarpara',
      label: 'Uttarpara',
      region: 'west-bengal',
    },
    {
      value: 'vadapalani',
      label: 'Vadapalani',
      region: 'tamil-nadu',
    },
    {
      value: 'vadodara',
      label: 'Vadodara',
      region: 'gujarat',
    },
    {
      value: 'vaikom',
      label: 'Vaikom',
      region: 'kerala',
    },
    {
      value: 'vaishali',
      label: 'Vaishali',
      region: 'bihar',
    },
    {
      value: 'vaishali-nagar',
      label: 'Vaishali Nagar',
      region: 'rajasthan',
    },
    {
      value: 'valpoi',
      label: 'Valpoi',
      region: 'goa',
    },
    {
      value: 'valsad',
      label: 'Valsad',
      region: 'gujarat',
    },
    {
      value: 'vandalur',
      label: 'Vandalur',
      region: 'tamil-nadu',
    },
    {
      value: 'vaniyambadi',
      label: 'Vaniyambadi',
      region: 'tamil-nadu',
    },
    {
      value: 'vapi',
      label: 'Vapi',
      region: 'gujarat',
    },
    {
      value: 'varanasi',
      label: 'Varanasi',
      region: 'uttar-pradesh',
    },
    {
      value: 'varca-beach',
      label: 'Varca Beach',
      region: 'goa',
    },
    {
      value: 'varkala',
      label: 'Varkala',
      region: 'kerala',
    },
    {
      value: 'vasai-virar',
      label: 'Vasai Virar',
      region: 'maharashtra',
    },
    {
      value: 'vasant-kunj',
      label: 'Vasant Kunj',
      region: 'delhi',
    },
    {
      value: 'vasant-vihar',
      label: 'Vasant Vihar',
      region: 'delhi',
    },
    {
      value: 'vasanth-nagar',
      label: 'Vasanth Nagar',
      region: 'karnataka',
    },
    {
      value: 'vashi',
      label: 'Vashi',
      region: 'maharashtra',
    },
    {
      value: 'vastrapur',
      label: 'Vastrapur',
      region: 'gujarat',
    },
    {
      value: 'velachery',
      label: 'Velachery',
      region: 'tamil-nadu',
    },
    {
      value: 'vellore',
      label: 'Vellore',
      region: 'tamil-nadu',
    },
    {
      value: 'veraval',
      label: 'Veraval',
      region: 'gujarat',
    },
    {
      value: 'vidisha',
      label: 'Vidisha',
      region: 'madhya-pradesh',
    },
    {
      value: 'vidyaranyapura',
      label: 'Vidyaranyapura',
      region: 'karnataka',
    },
    {
      value: 'vijay-nagar',
      label: 'Vijay Nagar',
      region: 'karnataka',
    },
    {
      value: 'vijayanagar',
      label: 'Vijayanagar',
      region: 'karnataka',
    },
    {
      value: 'vijayawada',
      label: 'Vijayawada',
      region: 'andhra-pradesh',
    },
    {
      value: 'vikhroli',
      label: 'Vikhroli',
      region: 'maharashtra',
    },
    {
      value: 'vile-parle',
      label: 'Vile Parle',
      region: 'maharashtra',
    },
    {
      value: 'villivakkam',
      label: 'Villivakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'viman-nagar',
      label: 'Viman Nagar',
      region: 'maharashtra',
    },
    {
      value: 'vip-bazar',
      label: 'VIP Bazar',
      region: 'west-bengal',
    },
    {
      value: 'viramgam',
      label: 'Viramgam',
      region: 'gujarat',
    },
    {
      value: 'virugambakkam',
      label: 'Virugambakkam',
      region: 'tamil-nadu',
    },
    {
      value: 'visakhapatnam',
      label: 'Visakhapatnam',
      region: 'andhra-pradesh',
    },
    {
      value: 'visharant-wadi',
      label: 'Visharant Wadi',
      region: 'maharashtra',
    },
    {
      value: 'vishrantwadi',
      label: 'Vishrantwadi',
      region: 'maharashtra',
    },
    {
      value: 'vizianagaram',
      label: 'Vizianagaram',
      region: 'andhra-pradesh',
    },
    {
      value: 'vyasarpadi',
      label: 'Vyasarpadi',
      region: 'tamil-nadu',
    },
    {
      value: 'wadala',
      label: 'Wadala',
      region: 'maharashtra',
    },
    {
      value: 'wagholi',
      label: 'Wagholi',
      region: 'maharashtra',
    },
    {
      value: 'wakad',
      label: 'Wakad',
      region: 'maharashtra',
    },
    {
      value: 'warangal',
      label: 'Warangal',
      region: 'telangana',
    },
    {
      value: 'wardha',
      label: 'Wardha',
      region: 'maharashtra',
    },
    {
      value: 'warje',
      label: 'Warje',
      region: 'maharashtra',
    },
    {
      value: 'washermanpet',
      label: 'Washermanpet',
      region: 'tamil-nadu',
    },
    {
      value: 'wazirabad',
      label: 'Wazirabad',
      region: 'delhi',
    },
    {
      value: 'west-mambalam',
      label: 'West Mambalam',
      region: 'tamil-nadu',
    },
    {
      value: 'whitefield',
      label: 'Whitefield',
      region: 'karnataka',
    },
    {
      value: 'wilson-garden',
      label: 'Wilson Garden',
      region: 'karnataka',
    },
    {
      value: 'worli',
      label: 'Worli',
      region: 'maharashtra',
    },
    {
      value: 'yamunanagar',
      label: 'Yamunanagar',
      region: 'haryana',
    },
    {
      value: 'yashodham',
      label: 'Yashodham',
      region: 'maharashtra',
    },
    {
      value: 'yavatmal',
      label: 'Yavatmal',
      region: 'maharashtra',
    },
    {
      value: 'yelahanka',
      label: 'Yelahanka',
      region: 'karnataka',
    },
    {
      value: 'yerwada',
      label: 'Yerwada',
      region: 'maharashtra',
    },
    {
      value: 'yeshwanthpur',
      label: 'Yeshwanthpur',
      region: 'karnataka',
    },
    {
      value: 'zirakpur',
      label: 'Zirakpur',
      region: 'punjab',
    },
    {
      value: 'ziro',
      label: 'Ziro',
      region: 'arunachal-pradesh',
    },
  ],
};

export default settings;
